import React from "react";

export default class TourView extends React.Component {

    addBikeHire = () => {
        let { bikes,update } = this.props
        bikes.push({})
        update({bikes})
    }
    
	render(){
        const { tourImage, tourTitle, tourSubTitle, tourDescription, tourFeatures } = this.props.tourDetails
		return (
            <div className="col-12 col-lg-6 col-xl-4 my-3 bike-view-col">
                <div className="card">
                    <div className="bike-view-header">
                        <div className='bike-view-header-title'><b>{tourTitle} Tour</b></div>
                    </div>
                    <img src={tourImage} alt="" className="card-img-bottom"/>
                    <div className="card-body">
                        <div className="card-text">
                            {tourSubTitle}
                            {tourDescription}
                            <ul>
                                {tourFeatures.map( f => 
                                    <li key={f}>{f}</li>    
                                )}
                            </ul>
                        </div>

                        <button onClick={this.addBikeHire} className='btn btn-block btn-outline-secondary'>Add Another Rider to this Tour</button>
                    </div>
                </div>
            <style jsx='true'>
                {`

                @media (max-width: 575px) {
                    .bike-view-col {
                        padding: 0px!important;
                    }
                }
                
                .bike-view-header {
                    background: #027ab7;
                    padding: 0.5em 1em;
                }
                
                .bike-view-delete-button {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    padding: 0;
                    border: 3px solid #fff;
                    padding-bottom: 5px;
                    box-shadow: none;
                }
                
                .bike-view-header-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                }

                .disabled-option {
                    color:#ddd;
                }
                
                `}
            </style>
        </div>
		)
	}
}
