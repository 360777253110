import React from "react";
import ScrollableAnchor from 'react-scrollable-anchor'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

const Intro = props => {
	return (
  	<React.Fragment>
  		<ScrollableAnchor id={'welcome'}>
  			<div className='container-fluid'>
				<div className='row'>
		  			<div className='col-12 col-md-6 px-0'>
		  				<div className='p-5 bg-color-2'>
			  				<h2 className='info-title'>Kia Ora</h2>
			  				<p>Welcome to Adventure Bike Hire</p>
			  				<p>We provide a mobile cycle hire and shuttle service for the Hauraki Rail Trail.</p>
			  				<p>Our mission is to make cycle hire easy - so you can relax and enjoy your adventure without having to worry about the logistics and equipment. </p>
							<p>We have bikes to suit anyone including e-bikes and kids bikes.  Check out our range <Link to='/bikehire'>here</Link> or <Link to ='/new-booking'>book online</Link> to start your next adventure.</p>
						</div>	  				
		  			</div>
		  			<div className='col-12 col-md-6 px-0 bg-image-2'>
		  			</div>
		  		</div>
		  	</div>
	  	</ScrollableAnchor>
   		<style jsx='true'>
	    	{`
	    		.bg-image-2 {
	    			background: url('/images/tunnel.jpg') no-repeat center center;
	    			background-size: cover;
	    			background-position: center;
	    			min-height: 350px;
	    		}

		        .bg-color-2 {
	    			background: #fff;
	    			color: #231f20;
	    			height:100%;
	    		}

    		`}</style>
    	</React.Fragment>
    )
}

export default withRouter(Intro)