export const TOWNSDISPLAY = {
    waihi_beach: 'Waihi Beach',
    waihi: 'Waihi',
    waikino: 'Waikino',
    karangahake: 'Karangahake',
    paeroa: 'Paeroa',
    te_aroha: 'TeAroha',
    matamata: 'Matamata',
    thames: 'Thames',
    miranda: 'Miranda',
    kaiaua: 'Kaiaua',
    other: 'Other'
}
