import React from "react";
import { Link } from 'react-router-dom'

const Bikes = props => {
	return (
  	<React.Fragment>
  			<div className='container-fluid px-0'>
  				<div className='container py-5'>
	  				<div className='row align-items-center'>
	  					<div className='d-none d-sm-block col-auto'>
	  						<img src='/images/ebike_sm.jpg' width='300px' alt='E Bikes'/>
	  					</div>
	  					<div className='col'>
	  						<h2>Our Bikes</h2>
	  						<p>At <b>Adventure Bike Hire</b> we have a great range of quality bikes available for hire.  There's something for everyone!  E-bikes, mountain bikes, comfort bikes, kids bikes, and more.</p>
							<p className='lead'>Check out our range <Link to='/bikehire'>here</Link></p>
						</div>
					</div>
				</div>

	  			<div className='row no-gutters px-0'>
	  				<div className='col'>
	  					<img  className='bike-img' src='/images/avanti.jpg' alt='Comfort Bikes' />
	  				</div>
	  				<div className='col'>
	  					<img className='bike-img' src='/images/marin.jpg' alt='Mountain Bikes' />
	  				</div>
	  				<div className='d-none d-md-block col'>
	  					<img className='bike-img' src='/images/kids.jpg' alt='Kids Bikes' />
	  				</div>
	  			</div>
				
		  	</div>
   		<style jsx='true'>
	    	{`
	    		.bike-img {
	    			max-width:100%;
	    		}
    		`}</style>
    	</React.Fragment>
    )
}

export default Bikes