import React from "react";
import FaqItem from '../components/faq-item'

const qa = [
		{
			q:'Do I need to book in advance?',
			a:"Bookings are essential as we are a mobile service.  You can book at short notice by phoning us on 027 398 2866."
		},
		{
			q:'Do you provide helmets with your bike rentals?',
			a:"Yes, helmets are included with each rental at no extra cost."
		},
		{
			q:'Are locks included with bike rentals?',
			a:'Yes, we provide enough locks for your group so you can secure the bikes if you plan to leave them unattended for a short time.'
		},
		{
			q:"What if it's raining on the day?  Can I cancel my booking?",
			a:'Yes, no problem.  Just let us know an hour before the scheduled start time for a refund or book a different day.'
		},
		{
			q:"Do you have eftpos?",
			a:"Yes we have mobile eftpos for Mastercard, Visa, or EFTPOS transactions.  We also accept cash and bank transfer."
		},
		{
			q:"What age are your kids bikes suitable for?",
			a:"Our kids mountain bikes are fine for most 7-12 yr olds."
		},
		{
			q:"Do I need a torch for the tunnel?",
			a:"The tunnel in the Karangahake Gorge which you can cycle through is lit well enough that a torch is not necessary.  However, if you plan to do the Windows Walk we recommend you bring your own torch."
		},
		{
			q:'Can I come to your shop and pick up the bikes?',
			a:"No, we are a mobile only service.  We can meet you anywhere on the trail, at your accommodation or starting point for your ride."
		},
		{
			q:'Do you offer discounts for multi-day or group bookings?',
			a:'Sure please contact us with the details of your group for a price.'
		},
		{
			q:'What if I get a flat tire on one of your bikes?',
			a:'In the unlikely event that you have any problems with our hire bikes, just give us a call.  We will get a replacement bike to you ASAP.'
		}
	]

export default class FAQ extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

		

	render() {
		return (
	    	<React.Fragment>
	    		<div className='container pt-3'>
	    			<h1>Frequently Asked Questions</h1>
	    			{qa.map((item,idx)=>
	    				<FaqItem key={idx} question={item.q} answer={item.a}/>
    				)}
	    		</div>
	    	</React.Fragment>
	    )
	}
}

// discounts
// come to u
// flat tire
