import React from "react";
import { withRouter } from 'react-router'

const CallToAction = props => {
	return (
  	<React.Fragment>
      <div className='container-fluid bg-primary cta mt-5'>
        <div className='container py-5 text-center'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              {!!props.tease && <h2>Ready to start your adventure?</h2>}
              <h3>Phone 027 398 2866</h3>
              <p>and speak to one of our friendly team, or</p>
            </div>
            <div className='col-12 col-md-6'> 
      		    <button className='btn btn-success cta-button' onClick={()=>props.history.push('/new-booking')}>BOOK ONLINE</button>
            </div>
          </div>
          </div>
        </div>
        <style jsx='true'>
	    	{`
          .cta h2{
            color: #fff;
            font-weight:100;
            margin-bottom:20px;
          }
          .cta h3{
            color: #fff;
            font-weight:800;
            font-size: 2rem;
          }

          .cta p {
            color: #fff;
            font-size: 1.2rem;
            font-weight: 100;
          }
	    	  .cta-button {
            border-radius: 25px;
            font-weight: 800;
            padding: 10px 25px;
            font-size: 1.5rem;
            color:#fff
          }

    		`}</style>
    	</React.Fragment>
    )
}

export default withRouter(CallToAction)