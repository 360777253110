import React from "react";
import { withRouter } from 'react-router'
import Trip from '../components/adventureshuttle/trip'
import RouteMatch from '../components/adventureshuttle/route-match'
import moment from "moment";
/*
const testData = [
    { 
        d: '20200906',
        dt: 'Sun 6 Sep',
        shuttles: [
            { 
                id: 'xxx000',
                sectors: [
                    { 
                        dept: 'Waihi', 
                        arr:'Waikino',
                        dept_time: '0900',
                        arr_time: '0915',
                        seats: 9,
                        m: 0.4
                    },
                    {
                        dept: 'Waikino',
                        arr: 'Karangahake',
                        dept_time: '0920',
                        arr_time: '0930',
                        seats: 9,
                        m: 0.4
                    },
                    {
                        dept: 'Karangahake',
                        arr: 'Paeroa',
                        dept_time: '0940',
                        arr_time: '0945',
                        seats: 7,
                        m: 0.2
                    }
                ]
            }
        ]
    },
    {
        d: '20200907',
        dt: 'Mon 7 Sep'
    },
    {
        d: '20200908',
        dt: 'Tue 8 Sep'
    },
    {
        d: '20200909',
        dt: 'Wed 9 Sep'
    },
    {
        d: '20200910',
        dt: 'Thu 10 Sep'
    },
    {
        d: '20200911',
        dt: 'Fri 11 Sep'
    },
    {
        d: '20200912',
        dt: 'Sat 12 Sep'
    }
]
*/


class AdventureShuttle extends React.Component {
    
    parseUrl = () => {
        // get params from path
        let urlString = this.props.location.pathname.toLowerCase().split('adventureshuttle/')[1] || ''
        
        // remove trailing '/' if any
        if (urlString.charAt(urlString.length -1) === '/') {
            urlString = urlString.substring(0,urlString.length-1)
        }

        // split into array
        const params = urlString.split('/')

        // abort if uneven # of params
        if (params.length % 2 === 1) {
            this.props.history.push('/adventureshuttle/')
            return {}
        }
        
        // map params into key/value pairs
        let obj = {}
        while (params.length) {
            obj[params[0]] = params[1]
            params.shift()
            params.shift()
        }
        
        return obj
    }
    
    state = { trip: {...this.parseUrl()} }
    
    componentDidMount() {
        window.scrollTo(0, 0)
        this.requestShuttles()
    }

    update = obj => {
        const trip = obj.trip
        this.setState(obj)

        // update url
        this.updateParams(trip)
            
        //fetch available shuttles
        this.requestShuttles(trip.from, trip.to, trip.date)
    }

    requestShuttles = (
            from = this.state.trip.from,
            to = this.state.trip.to,
            date = this.state.trip.date ) => {
        console.log(from, to, date)
        //const { from, to, date } = this.state.trip
        if (to && date && to.length && date.length) {
            
            // we have required input to fetch available shuttles
            let key = `${date}.${from}.${to}`
            this.setState({selected: key})
            
            if (!this.state[key]) {
                // only perform http request if data !exist
                console.log('checking...')
                this.setState({[key]:'some data for ' + moment(key.split('.')[0], 'YYYYMMDD').format('Do MMM')})
            }

        } else {
            // clear selected
            this.setState({selected: null})
        }
    }
    
    updateParams = (params) => {
        let urlString = '/adventureshuttle/'
        for (const [k,v] of Object.entries(params)) {
            urlString += `${k}/${v}/`
        }
        this.props.history.push(urlString)
    }

	render(){
        console.log(this.state)
        const { trip = {}, selected} = this.state
		return (
	    	<>
	    		<div className="container">
                    <Trip trip={trip} update={this.update}/>
                    <RouteMatch trip={trip} update={this.update} result={this.state[selected]}/>
                </div>
				<style jsx='true'>
                    {`
                   
					`}
				</style>
	    	</>
	    )
	}
}
export default withRouter(AdventureShuttle)