export const tours = {
    kgorge: {
        tourDetails: {
            tourTitle: 'K-Gorge Discovery',
            tourSubTitle: 'Explore Paeroa, Karangahake Gorge, and Waihi',
            tourDescription: 'Starting from Waihi in the morning, you will catch our shuttle van to Paeroa and cycle back along the riverside towards Waihi. Includes bike hire, helmet, trail map, shuttle transfer.',
            tourFeatures: [
                '4-6 hours / 24kms',
                'Departs Waihi at 10am',
                'Includes shuttle to Paeroa, bike hire, helmet, trail map'
            ],
            tourImage: '/images/packages-promo.jpg',
        },
        fixedNumberDays: 1,
        packagePrice: {
            adult: 90,
            ebike: 140,
            kids: 70,
        },
        features: {

        },
        bikes: [
            {
                hideCloseButton: true,
            },
            {
                hideCloseButton: true,
            }
        ]
    }
}
