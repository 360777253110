import React from "react";
import Review from './review_widget'

export default class Reviews extends React.Component {
	
	render(){

		return (
	  		<React.Fragment>
	  			<div className='container-fluid my-5 px-0'>
					<div className='container'>
						<h2>Our Customers Say...</h2>
					</div>	  				
	  				<Review title='Excellent Service and Bike'
	  					date='March 2019'
	  					text='The overall service and quality of the equipment was first class. My wife commented her bike was the most comfortable she had ever ridden!'
	  					bgcolor='#fff'
	  				/>

	  				<Review title='Great way to see New Zealand'
	  					date='Feb 2019'
	  					text='Great service. Des was so easy to communicate with and incredibly helpful. The most flexible and high quality service of any of Hauraki trial operators that I have dealt with. The Waihi to Paeroa leg of the Hauraki rail trail is a New Zealand cycling highlight. We did it both ways and included the windows walk in one direction and the tunnel and a waterfall swim going the other way. My Dutch friend had mainly ridden on flat roads before and loved the chance to see the bush and highlights without facing New Zealand road traffic.'
	  					bgcolor='#d2dae2'
	  				/>

	  				<Review title='Reliable, simple and honest'
	  					date='Jan 2019'
	  					text="Adventure bike hire is great. I organised our bikes (with Des?) over the phone the day before we wanted to cycle. We were met at Waihi train station by Emily the following morning with 2 great bikes and the offer of being picked up at any time should we run out of steam (we made it back without help). The ride is self-explanatory as it follows the river, but as a couple of mid-50's, with no recent riding behind us, we decided to turn around at Karangahake Gorge - about 32km return. Absolutely beautiful ride. We gave Adventure Bikes a call on return and left the bikes locked up at the station. Couldn't have been easier. PS: there is no phone bank to leave a message if they are out of range. If you can't get through, send a text or email. Highly recommend you go with them."
	  					bgcolor='#fff'
	  				/>

	  				<Review title='Excellent service'
	  					date='Jan 2019'
	  					text="Des runs an excellent service. He was prompt and helpful. Everything was in excellent condition. Pick up time and place was flexible and this suited our needs. It was great to have some freedom to plan our day. The ride through Karangahake gorge was stunning. We left from Waihi station and rode to Paeroa. Had lunch in Poeroa then rode back to Waikini station. Then caught the train with our bikes back to Waihi station. Plenty of good places to stop and eat, and non stop beautiful scenery. An easy flat ride. Our three teenagers loved the tunnel and the stop to do short walks along the way. Thanks Des for making this an easy, affordable and enjoyable day."
	  					bgcolor='#d2dae2'
	  				/>
	  			</div>
	  		
				<style jsx='true'>
		    	{`
	    		`}</style>
	    	</React.Fragment>
	    )
	}
}

