import React from "react";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
const times = ['', 'TBA', '8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm']
const times_ = ['', 'TBA', '0800', '0815','0830','0845','0900','0915','0930','0945','1000','1015','1030','1045','1100','1115','1130','1145','1200','1215','1230','1245','1300','1315','1330','1345','1400','1415','1430','1445','1500','1515','1530','1545','1600','1615','1630','1645','1700','1715','1730','1745','1800']

export default class SelectDate extends React.Component {
    
    state={}

    handleDayClick = (from) => {
        this.setState({from}, this.update)
    }

    onChange = ev => {
        this.setState({fromTime: ev.target.value}, this.update)
    }

    update = () => {
        const { from, fromTime } = this.state

        const dt = fromTime ? moment(from).format('YYYYMMDD') + 'T'
        + (fromTime === 'TBA' ? '0759' : fromTime) : undefined
        this.props.onUpdate(dt)
    }

    handleClearSelection = () => {
        this.props.onUpdate(undefined)
    }

	render(){
        const { from, fromTime } = this.state
        const dateFormat = 'dddd, MMM Do YYYY'
		return (
            <>
                <div style={{border: '1px solid #eee', padding: 12, borderBottom: 'none'}}>
                    <div className='booking-header'>
                        <h5>Choose Booking Date</h5>
                    </div>
                
                    <div className="row">
                        <div className="col-12 col-md-auto text-center">
                            <DayPicker
                                selectedDays={[from]}
                                onDayClick={this.handleDayClick}
                                disabledDays={{before: new Date()}}/>
                            <br/>
                            <button className='btn btn-link' onClick={this.handleClearSelection}>Clear Selection</button>
                        </div>
                        <div className="col-12 col-md-6">
                            {from && <>
                            <hr className='d-block d-md-none'/>
                            <h5>{moment(from).format(dateFormat)}</h5>
                            <div className="form-group">
                                <label htmlFor="" className="form-label"><b>Preferred Pickup Time</b></label>
                                <select className='form-control' id='fromTime' value={fromTime} onChange={this.onChange}>
                                    { times.map( (t, i) => 
                                        <option key={i} value={times_[i]}>{t}</option>)}
                                </select>
                            </div>
                            <p className='alert alert-info'><small>
                                <b>Not sure when you need the shuttle to arrive?</b><br/>
                                Select <em>TBA</em> (to be advised) and keep in contact with us on the day to confirm a time.
                                
                            </small></p>

                            { !fromTime &&
                            <p className='text-danger'>* Please select a pickup time</p>
                            }
                            </>}
                        </div>
                    </div>
                </div>
                <style jsx='true'>
                    {`
                      
                    `}
                </style>
            </>
		)
	}
}
