import React from "react";
class BikesForSale extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){
		return (
	    	<>
	    		<div className='container mt-5'>
                    <h1>Ex-Rental Bikes For Sale</h1>
                    
                    <div className="row">
                        <div className="col">
                            <p>As we update our rental bike fleet, we often have 2nd-hand bikes and ebikes for sale.  We try to keep this page up to date but not all available bikes are listed here.  If you are looking for something specific, please reach out by phone or email and we will do our best to help. </p>
                            <p><b className="lead">Try before you buy..  </b>Book a day rental for one of the bikes we have for sale and if you decide to buy it we take the rental fee off the purchase price.</p>
                            <p>Bikes are checked and maintained to good working order.  Condition will vary depending on age and use.  We recomend viewing in person to ensure you are happy with your purchase.  Please contact us with any enquires.  </p>
                        </div>
                        <div className="col-auto">
                            <img src='images/trybeforebuy.png' alt='Try Before You Buy'/>
                        </div>
                    </div>
                    <div className="row">
                        {/*  SMARTMOTION XCITY 27 
                        <div className="card m-3" style={{width: '23rem'}}>
                            <div className="card-body">
                                <h5 className="card-title">Smartmotion XCITY 27 - $2000</h5>
                                <img src='/images/bikesforsale/xcity27.jpg' width='100%'/>
                                <ul>
                                    <li>Size: Adult Medium</li>
                                    <li>27.5" Wheels</li>
                                    <li>2800 hours from new</li>
                                    <li>Protective Frame wrap</li>
                                    <li>Brand new forks</li>
                                    <li>Comes with charger & key</li>

                                </ul>
                            </div>
                        </div>

                        {/*  BAYVIEW TRAIL 24 */}
                        <div className="card m-3" style={{width: '23rem'}}>
                            <div className="card-body">
                                <h5 className="card-title">Marin Bayview Trail 24 - $300</h5>
                                <img src='/images/bikesforsale/bayviewtrail.jpg' width='100%'/>
                                <ul>
                                    <li>Size: Kids 24" Wheels - suit 8-10yrs</li>
                                    <li>7sp with twist shifter</li>
                                    <li>front & rear disc brakes</li>
                                    <li>front suspension</li>
                                    <li>2 available</li>
                                </ul>
                            </div>
                        </div>

                        {/*  STORM 2 */}
                        <div className="card m-3" style={{width: '23rem'}}>
                            <div className="card-body">
                                <h5 className="card-title">Norco Storm 2 - $350</h5>
                                <img src='/images/bikesforsale/storm2.jpg' width='100%'/>
                                <ul>
                                    <li>Sizes available: Adults Small or XL </li>
                                    <li>Capable Hardtail Mountain Bike</li>
                                    <li>1x10 Shimano Deore</li>
                                    <li>Hydraulic disc brakes - 160mm rotors</li>
                                    <li>100mm travel front suspension</li>
                                    
                                </ul>
                            </div>
                        </div>

                         {/*  MERIDA ESPRESSO 500EQ  */}
                         <div className="card m-3" style={{width: '23rem'}}>
                            <div className="card-body">
                                <h5 className="card-title">Merida Espresso 500EQ - $2800</h5>
                                <img src='/images/bikesforsale/espresso500eq.jpg' width='100%'/>
                                <ul>
                                    <li>Multiple Sizes available: Adults XS-XL </li>
                                    <li>630wH integrated battery</li>
                                    <li>3 Assist modes up to 200km range</li>
                                    <li>1x10 Shimano Deore</li>
                                    <li>Hydraulic disc brakes - 160mm rotors</li>
                                    <li>100mm travel front suspension</li>

                                    
                                </ul>
                            </div>
                        </div>

                    </div>
               
                </div>

                <style>
                {`
               
                    
                `}
                </style>                   
	    	</>
	    )
	}
}

export default BikesForSale