import React from "react";
import DayPicker from 'react-day-picker';
import moment from 'moment'
import 'react-day-picker/lib/style.css';
import { TOWNS, LOCATIONS } from './constants'



class Trip extends React.Component {

    
    state = {open: false}

    handleDayClick = (date,modifiers) => {
        if (!modifiers.disabled) {
            let trip = {...this.props.trip}
            trip.date = moment(date).format('YYYYMMDD')
            this.props.update({trip:trip})
            this.setState({open:false})
        }
    }

    onChange = ev => {
        let trip = {...this.props.trip}      
        trip[ev.target.id] = ev.target.value

        // clear dependant values
        if (ev.target.id === 'from') {
            delete trip.to
            delete trip.pickup_location
            delete trip.dropoff_location
            if (!ev.target.value.length) delete trip.from
        }

        if (ev.target.id === 'to') {
            delete trip.dropoff_location
        }
        this.props.update({trip:trip})
    }

    getAvailableLocations = town => {
        return LOCATIONS[town]
    }

    validate = field => {
        const {from='', to='', pickup_location='', dropoff_location=''} = this.props.trip
        switch (field) {
            case 'to': return from.length && !to.length ? 'is-invalid' : ''
            case 'pickup_location': return from.length && !pickup_location.length ? 'is-invalid' : ''
            case 'dropoff_location': return to.length && !dropoff_location.length ? 'is-invalid' : ''
            default: return null;
        }
    }

	render(){
        const { from, to, pickup_location, dropoff_location } = this.props.trip
        const date = this.props.trip.date ? moment(this.props.trip.date,'YYYYMMDD')._d : ''
        const PICKUP_LOCATIONS = this.getAvailableLocations(from)
        const DROPOFF_LOCATIONS = this.getAvailableLocations(to)
        
        return (
            <div className="card mt-5">
                <div className="card-header"><h5>Trip Details</h5></div>
                <div className="card-body">
                    <div className="form-group">
                        <label>From</label>
                        <select className='form-control' value={from||''} onChange={this.onChange} id="from">
                            {TOWNS.map((t,i) => 
                                <option key={i} value={t.id}>{t.name}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>To</label>
                        <select className={`form-control ${this.validate('to')}`} value={to||''} onChange={this.onChange} id="to">
                            {from && from.length && TOWNS.map((t,i) => 
                                t.id !== from && <option key={i} value={t.id}>{t.name}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Pickup Location</label>
                        <select className={`form-control ${this.validate('pickup_location')}`} value={pickup_location||''} onChange={this.onChange} id="pickup_location">
                            {PICKUP_LOCATIONS && PICKUP_LOCATIONS.map((l,i)=>
                                <option key={i} value={i}>{l}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Dropoff Location</label>
                        <select className={`form-control ${this.validate('dropoff_location')}`} value={dropoff_location||''} onChange={this.onChange} id="dropoff_location">
                            {DROPOFF_LOCATIONS && DROPOFF_LOCATIONS.map((l,i)=>
                                <option key={i} value={i}>{l}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Date</label>
                        <input 
                            className='form-control' 
                            value={date && moment(date).format('dddd, MMM Do YYYY')} 
                            placeholder='Departure Date'
                            onChange={()=>{}}
                            onClick={()=>this.setState({open:true})}/>
                        <DayPicker
                            className={`${!this.state.open ? 'd-none' : 'position-absolute bg-white border z-up'}`}
                            selectedDays={[date]}
                            onDayClick={this.handleDayClick}
                            numberOfMonths={1}
                            disabledDays={{before: new Date()}}/>
                    </div>
                </div>
	    		
				<style jsx='true'>
                    {`
                        .z-up {
                            z-index: 1000;
                        }
                   `}
				</style>
            </div>
	    )
	}
}
export default Trip