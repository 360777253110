import React from "react";

const Review = props => {
	return (
  		<React.Fragment>
  			<div style={{background:props.bgcolor}}>
  				<div className='container py-3'>
	  				<h5 className='text-primary'>{props.title}</h5>
						<p><img width='100px' alt='' src='/images/tripadvisor-rated.png' className='mr-3'/>
						<small><em>{props.date}</em></small></p>
	  				<p>{props.text}</p>
	  			</div>
  			</div>
  		
			<style jsx='true'>
	    	{`
    		`}</style>
    	</React.Fragment>
    )
}

export default Review