import React from "react";
//import QuickQuote from '../components/shuttle/quickQuote'
//import PromoFooter from '../components/promo/footer'
class Shuttle extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){
		return (
	    	<>
	    		<div className='container mt-5'>
                    <h1>Packages</h1>

                    {/* K-GORGE DISCOVERY 
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>K-Gorge Discovery</h2>
                            <p>
                                <span className="lead text-danger">Explore Paeroa, Karangahake Gorge, and Waihi</span>
                                <span className="d-block text-primary">4-6 Hours / 24kms</span>
                            </p>
                            <p>Starting from Waihi in the morning, you will catch our shuttle van to Paeroa and cycle back along the riverside towards Waihi.  Includes bike hire, helmet, trail map, shuttle transfer.</p>
                            <p>
                                <span className="text-success d-block">Adult E-Bike: $140</span>
                                <span className="text-success d-block">Adult Mountain Bike or Comfort Bike: $90</span>
                                <span className="text-success d-block">Child Standard Bike: $70</span>
                            </p>
                            <p><em>* Minimum 2 people</em></p>

                        </div>
                        <div className="col-12 col-md-6"><img alt="" src='/images/packages-promo.jpg' className='image'/></div>
                    </div>

                    <hr/>
        */}

                    {/* TEAROHA WEST */}
                    <div className="row mt-4">
                        <div className="col-12 col-md-6">
                        <h2>Country Railroad</h2>
                            <p>
                                <span className="lead text-danger">Explore Section D – TeAroha to Paeroa</span>
                                <span className="d-block text-primary">4-6 Hours / 30kms</span>
                            </p>
                            <p>Catch our shuttle from Paeroa in the morning.  A short trip to TeAroha West where you start the trail from The Old Forge Kitchen.   Enjoy views of the Kaimai Ranges as you cycle an easy 30km via TeAroha Town back to Paeroa.</p>
                            <p>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Bike hire, helmet, pannier bag, lock, trail map</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Voucher for cafe in TeAroha</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Shuttle Transfer</span>
                            </p>
                            <p>
                                <span className="text-success d-block">Adult E-Bike: $145</span>
                                <span className="text-success d-block">Adult Mountain Bike or Comfort Bike: $95</span>
                                <span className="text-success d-block">Child Standard Bike: $75</span>
                            </p>

                            <p><em>* Minimum 2 people</em></p>
                        </div>
                        <div className="col-12 col-md-6"><img alt="" src='/images/tearoha.jpg' className='image'/></div>
                    </div>
                    
                    <hr/>

                     {/* THAMES / CHEESE TOUR */}
                     <div className="row mt-4">
                        <div className="col-12 col-md-6">
                        <h2>Cheese Please</h2>
                            <p>
                                <span className="lead text-danger">Thames to Paeroa Section B</span>
                                <span className="d-block text-primary">4-6 Hours / 34kms</span>
                            </p>
                            <p>Leave your car in Paeroa and our shuttle van will pick you up in the morning.  Starting from the Gold Mining Heritage town of Thames, you will cycle the easy trail eastbound to Paeroa.  Highlights include the Matatoki Cheese Barn and the old Kopu Bridge.</p>
                            <p>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Bike hire, helmet, pannier bag, lock, trail map</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Voucher for Matatoki Cheese Barn</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Shuttle Transfer</span>
                            </p>
                            <p>
                                <span className="text-success d-block">Adult E-Bike: $155</span>
                                <span className="text-success d-block">Adult Mountain Bike or Comfort Bike: $105</span>
                                <span className="text-success d-block">Child Standard Bike: $85</span>
                            </p>

                            <p><em>* Minimum 2 people</em></p>
                        </div>
                        <div className="col-12 col-md-6"><img alt="" src='/images/thames.jpg' className='image'/></div>
                    </div>
                    
                    <hr/>

                     {/* 3 DAY */}
                     <div className="row mt-4">
                        <div className="col-12 col-md-6">
                        <h2>Easy as 1-2-3</h2>
                            <p>
                                <span className="lead text-danger">Thames, Paeroa, Waihi & TeAroha</span>
                                <span className="d-block text-primary">3 Days / 2 Nights</span>
                            </p>
                            <p><b>Day One - 34kms:</b> Leaving your vehicle securely parked in Paeroa, our shuttle will take you to the historic Gold Mining town of Thames to start the trail.  Cycle from Thames to Paeroa, enjoy some refreshments or cheese tasting at the Matatoki Cheese Barn enroute.</p>
                            <p><b>Day Two - 24kms:</b> Cycle section C from Paeroa to Waihi exploring the stunning Karangahake Gorge.</p>
                            <p><b>Day Three - 33kms:</b> Start with a train ride from Waihi to Waikino then cycle back through the Karangahake Gorge to Paeroa.  Onwards towards TeAroha with views of the Kaimai Ranges.  Our shuttle will pick you up from TeAroha and drop you off back at your vehicle in Paeroa.</p>
                            <p>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Bike hire, helmet, pannier bag, lock, trail map</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Voucher for Matatoki Cheese Barn</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Train Tickets</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> 2 nights accomodation</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Luggage Transfers each day</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Secured Parking</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Shuttle Transfers</span>
                            </p>
                            <p>
                                <span className="text-success d-block">Adult E-Bike: $700</span>
                                <span className="text-success d-block">Adult Mountain Bike or Comfort Bike: $580</span>
                            </p>

                            <p><em>* Minimum 2 people</em></p>
                        </div>
                        <div className="col-12 col-md-6"><img alt="" src='/images/3day.jpg' className='image'/></div>
                    </div>
                    
                    <hr/>

                     {/* 5 DAY */}
                     <div className="row mt-4">
                        <div className="col-12 col-md-6">
                        <h2>The Full Monty</h2>
                            <p>
                                <span className="lead text-danger">Miranda to Matamata</span>
                                <span className="d-block text-primary">5 Days / 4 Nights</span>
                            </p>
                            <p><b>Day One - 45kms:</b> Leaving your vehicle securely parked in Paeroa, our shuttle will take you to start the trail at Miranda.  After visiting the shorebird center and/or the bird-hide, the trail takes you via coastal wetlands through the towns of Waitakaruru, Pipiroa, Kopu, and finishing the day at Thames.</p>
                            <p><b>Day Two - 34kms:</b> Cycle section B from Thames to Paeroa, enjoy some refreshments or cheese tasting at the Matatoki Cheese Barn enroute.</p>
                            <p><b>Day Three - 24kms:</b> Cycle section C from Paeroa to Waihi exploring the stunning Karangahake Gorge.</p>
                            <p><b>Day Four - 33kms:</b> Start with a train ride from Waihi to Waikino then cycle back through the Karangahake Gorge to Paeroa.  Onwards towards TeAroha with views of the Kaimai Ranges.  Finish the day with a relaxing spa at TeAroha Mineral Spas.</p>
                            <p><b>Day Five - 37kms:</b> Cycle section E from TeAroha to Matamata.  Easy trail through farmland with views of the Kaimai ranges and Wairere Falls.  Highlights include Firth Tower – gardens and museum.  Meet up with our shuttle van in Matamata for a lift back to your vehicle in Paeroa.</p>
                            <p>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Bike hire, helmet, pannier bag, lock, trail map</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Voucher for Matatoki Cheese Barn</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Voucher for Mineral Spas</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Train Tickets</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> 4 nights accomodation</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Luggage Transfers each day</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Secured Parking</span>
                                <span className="d-block ml-3"><span className="text-success">&#10003;</span> Shuttle Transfers</span>
                            </p>
                            <p>
                                <span className="text-success d-block">Adult E-Bike: $1250</span>
                                <span className="text-success d-block">Adult Mountain Bike or Comfort Bike: $950</span>
                            </p>

                            <p><em>* Minimum 2 people</em></p>
                        </div>
                        <div className="col-12 col-md-6"><img alt="" src='/images/5day.jpg' className='image'/></div>
                    </div>
                    
                    <hr/>

                  

                    <p className='text-primary mt-2'><b>Call or Email for more information or to book packages - (not available to book online)</b></p>

                    <hr/>
                    
                </div>

				<style jsx='true'>
                    {`
                    .shuttle-caption {
                        color: #fff;
                        padding: 4px 6px;
                        text-align: center;
                    }
					
					`}
				</style>
	    	</>
	    )
	}
}

export default Shuttle