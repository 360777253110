export const TOWNS = [
    '',
    { id: 'ca', name: 'Waihi' },
    { id: 'cb', name: 'Waikino' },
    { id: 'cc', name: 'Karangahake' },
    { id: 'cd', name: 'Paeroa' }]

export const ID_TOWN_MAP = {
    ca: 'Waihi',
    cb: 'Waikino',
    cc: 'Karangahake',
    cd: 'Paeroa'
}
        
export const LOCATIONS = {
    'ca': ['','Goldfields Railway', 'Seddon St Bus Station'],
    'cb': ['','Waikino Station Cafe', 'Waikino Hotel', ],
    'cc': ['','The Falls Retreat', 'Karangahake Gorge Main Carpark', 'Karangahake Hall'],
    'cd': ['','Ohinemuri Park (Giant L&P Bottle)', 'Paeroa Bus Station']
}