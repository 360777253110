import React from "react";
//import { TOWNS, TOWNS_DISPLAY, getPrice } from '../../components/shuttle-prices'
import { STATIONS_VIEW, getPrice } from "../shuttle-calc";

export default class Shuttle extends React.Component {

    componentDidMount() {
        //force update shuttle.cost
        this.onChange({target: {id: 'pax', value: this.props.shuttle.pax || 0}})
    }
    
    onChange = ev => {
        const { onUpdate, shuttle } = this.props
        shuttle[ev.target.id] = ev.target.value
        const { pax = 0, fromTown = '', toTown = '' } = shuttle
        shuttle.cost = pax > 0 ? getPrice({pax, start: fromTown, finish: toTown}) : undefined
        onUpdate([shuttle])
    }

	render(){

        const { shuttle={} } = this.props
        const { fromTown='', fromAddress='', toTown='', toAddress='', pax='', cost } = shuttle
 
		return (
            <div className="booking-section ">
                <div className="full-width-row">
                    <div className="form-row mt-3">
                        <div className="col-5">
                            <div className="form-group">
                                <label className="form-label"><b>Number of Passengers</b></label>
                                <select value={pax ||''} className="form-control" id={'pax'} onChange={this.onChange}>
                                    <option></option><option>1</option><option>2</option><option>3</option><option>4</option><option>5</option>
                                    <option>6</option><option>7</option><option>8</option><option>9</option><option>10</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className="col-12 col-md-5">
                            <div className="form-group">
                                <label className="form-label"><b>Shuttle From</b></label>
                                <select value={fromTown || ''} className="form-control" id={'fromTown'} onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map((t,i) =>
                                        <option key={i} value={t}>{t}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="form-group">
                                <label className='d-none d-md-block form-label'>&nbsp;</label>
                                <input value={fromAddress || ''} className="form-control" placeholder='Address' id={'fromAddress'} onChange={this.onChange}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="form-group">
                                <label className="form-label"><b>Shuttle To</b></label>
                                <select value={toTown || ''} className="form-control" id={'toTown'} onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map((t,i) =>
                                        <option key={i} value={t}>{t}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="form-group">
                                <label className='d-none d-md-block form-label'>&nbsp;</label>
                                <input value={toAddress || ''} className="form-control" placeholder='Address' id={'toAddress'} onChange={this.onChange}/>
                            </div>
                        </div>
                    </div>
                    {   !pax > 0 ? <p className='text-danger'>* Please select how many passengers</p>
                        : !fromTown ? <p className='text-danger'>* Please select a pickup location</p>
                        : !toTown ? <p className='text-danger'>* Please select a destination location</p>
                        : toTown === fromTown ? <p className='text-danger'>* Start and Finish cannot be the same</p>
                        : <p><b className='text-success'>Price: NZD${cost}</b></p>
                    }
                </div>
                
                <style jsx='true'>
                    {`
                  
                    `}
                </style>
            </div>
		)
	}
}
