import React from "react";

class BookingSuccess extends React.Component {	

	componentDidMount() {
		window.scrollTo(0, 0)
	}
	
	render() {
		return (
			<div className='container-fluid px-0'>
				<div className='container py-5'>
					<h2 className='text-primary'>Thank you!  Your booking is now being processed..</h2>
					<p className='lead'>We are checking availability and will be in touch within 24 hrs.  Thankyou for choosing Adventure Bike Hire.</p>
				</div>					
				<img className='w-100' src='images/success.jpg' alt='' />
			</div>
	    )
	}
}

export default BookingSuccess