import React from "react";

export default class Terms extends React.Component {

    
    onChange = ev => {
        const { terms, onUpdate } = this.props
        terms.accepted = ev.target.checked
        onUpdate(terms)
    }

    render(){
        const { terms = {} } = this.props
        const { accepted = false } = terms

        return (
            <div className="col-12 col-lg-4 booking-tos">
                <h5>Terms</h5>
                <b>Terms of Service</b>
                <p>All riders ride at their own risk and must wear an approved safety helmet at all times while riding. (provided at no extra cost). </p>
                <p>The hirer accepts responsibility for the care and safekeeping of all equipment hired from Adventure Bike Hire and agrees to compensate Adventure Bike Hire for the full cost of repairing or replacing the equipment if it is damaged or lost through misuse or neglect.</p>
                <b>Cancellations</b>
                <p>All cancellations received prior to 1 hour before the booking start time will be entitled to a full refund of all payments made including the deposit.</p>
                <div className="form-check">
                    <input type='checkbox' className='form-check-input' id='tos' onChange={this.onChange} checked={accepted}/>
                    <label htmlFor='tos' className='form-check-label'><b>I have read and agree to the terms of service and cancellations policy.</b></label>
                </div>

                <hr/>


                
                <style jsx='true'>
                    {`
                        .booking-tos p {
                            font-size: 0.9em;
                        }
                   
                    `}
                </style>
            </div>
		)
	}
}
