import React from "react";
import axios from 'axios'
import { withRouter } from 'react-router'



class Bookings extends React.Component {

	constructor(props) {
		super(props)
        this.state = {}
        this.url = 'http://localhost:5000/adventure-bike-hire-admin/us-central1/api/testpay'
        
	}

    getShit= () => {

        axios.get(this.url)
            .then( res => {
                window.location.replace(res.data)
            })
            .catch ( err => {
                console.error(err)
            })
    }

	render(){
        const { result='' } = this.state

		return (
            <div className='container booking-form pt-3'>
                <button className="btn btn-primary btn-lg" onClick={this.getShit}>POST</button>
                <p>{result}</p>
            </div>
		)
	}
}

export default withRouter(Bookings)