import React from "react";
import { FaTimes, FaPlus } from "react-icons/fa/";
const TOWNS = ['','Waihi Beach', 'Waihi', 'Waikino', 'Paeroa', 'Te Aroha', 'Matamata', 'Thames', 'Miranda', 'Kaiaua', 'Other']


export default class BagTransfer extends React.Component {

    add = () => {
        const { onUpdate, luggage } = this.props
        luggage.push({date: this.props.dates[0], cost: undefined, bags: 1})
        onUpdate(luggage)
    }

    onDelete = idx => {
        const { onUpdate, luggage } = this.props
        luggage.splice(idx, 1)
        onUpdate(luggage)
    }

    onChange = ev => {
        const { luggage, onUpdate } = this.props
        const key = ev.target.id.split('.')[0]
        const idx = ev.target.id.split('.')[1]
        luggage[idx][key] = ev.target.value
        luggage[idx].cost = luggage[idx].fromTown && luggage[idx].toTown ? 100 : undefined
        onUpdate(luggage)
    }

	render(){

        const { luggage=[], dates } = this.props

		return (
            <div className="booking-section" style={{marginTop: '-24px'}}>
                <div className="full-width-row">
                    {luggage.map((s, idx) =>
                    <div className="card mb-4" key={idx}>
                        <button onClick={()=>this.onDelete(idx)}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>
                        <div className="bike-view-header">
                            <div className='bike-view-header-title'><b>Luggage Transfer #{idx+1}</b></div>
                        </div>

                        <div className="card-body">
                            <div className="form-row">
                                <div className="col-auto">
                                    <div className="form-group">
                                        <label className="form-label"><b>Date</b></label>
                                        <select className="form-control" value={s.date} onChange={this.onChange} id={'date.' + idx}>
                                            {dates.map((d,i) =>
                                                <option key={i}>{d}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="form-group">
                                        <label className="form-label"><b>Bags</b></label>
                                        <select className="form-control" value={s.bags} onChange={this.onChange} id={'bags.'+idx}>
                                            <option></option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className=" form-label"><b>From</b></label>
                                        <select className="form-control" value={s.fromTown} id={'fromTown.'+idx} onChange={this.onChange}>
                                            {TOWNS.map((t,i) =>
                                                <option key={i}>{t}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="form-group">
                                        <label className='d-none d-md-block form-label'>&nbsp;</label>
                                        <input className="form-control" placeholder='Address' value={s.fromAddress || ''} onChange={this.onChange} id={'fromAddress.'+idx}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className="form-label"><b>To</b></label>
                                        <select className="form-control" value={s.toTown} id={'toTown.'+idx} onChange={this.onChange}>
                                            {TOWNS.map((t,i) =>
                                                <option key={i}>{t}</option>)}
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-12 col-md-7">
                                    <div className="form-group">
                                        <label className='d-none d-md-block form-label'>&nbsp;</label>
                                        <input className="form-control" placeholder='Address' id={'toAddress.'+idx} value={s.toAddress || ''} onChange={this.onChange}/>
                                    </div>
                                </div>                               
                            </div>

                            {   !s.fromTown ? <p className='text-danger'>* Please select a pickup location</p>
                                : !s.toTown ? <p className='text-danger'>* Please select a destination location</p>
                                : <p><b className='text-success'>Price: NZD${s.cost}</b></p>
                            }

                        </div>
                    </div>
                    )}

                    <div className="add-shuttles d-flex align-items-center" onClick={this.add}>
                        <FaPlus className='mr-3'/>
                        <span>Click to add Luggage Transfer</span>
                    </div>
                </div>
                
                <style jsx='true'>
                    {`
                    .add-shuttles {
                        padding: 12px;
                        border: 1px dashed #999;
                        background: #eee;
                    }

                    .add-shuttles:hover {
                        cursor:pointer;
                    }
                  
                    `}
                </style>
            </div>
		)
	}
}
