import React from "react";
import { FaArrowDown } from "react-icons/fa/";

const Home = props => {
	return (
  	<React.Fragment>
  		<section className = 'hero'>
  			<h1 className='hero-text text-center'>
  				We Bring The <span className='yellow'>Bikes</span> To You
  			</h1>
				<a href='#welcome' aria-label="scroll" className='scroll-to-content yellow'>
					<FaArrowDown className='move'/>
				</a>
  		</section>

    		<style jsx='true'>
	    	{`
	    
	    		@media screen and (min-width: 1000px) {
	    			.hero-text {
	    				font-size: 4em !important;
	    			}
	    		}

	    		.hero-text {
	    			font-size: 3em;
	    			background: black;
	    			color: white;
	    			text-transform: uppercase;
	    			font-weight: 800;
	    			padding: 0 15px;
	    		}

	    		.yellow {
	    			color: #fbce03;
	    		}

	    		.hero {
	    			align-items: center;
	    			background: url('/images/hero.jpg') no-repeat center center;
	    			background-size: cover;
	    			display: flex;
			        flex-flow: column nowrap;
					justify-content: center;
					min-height: calc(100vh - 97px);
					height: 100px;
	    		}
	    		.scroll-to-content {
	    			text-decoration: none;
	    			background: #000;
	    			width:50px;
	    			height:50px;
	    			text-align:center;
		          	border: 0;
		          	border-radius: 50%;
		          	cursor: pointer;
		          	font-size: 2em;
	    		}

		        .move {
		        	animation-duration: 1s;
		            animation-name: buttonIconMove;
		            animation-iteration-count: infinite;
		        }

		        @keyframes buttonIconMove {
		          0% {
		            transform: translateY(0);
		          }
		          50% {
		            transform: translateY(-10px);
		          }
		          100% {
		            transform: translateY(0);
		          }
		        }

    		`}</style>
    	</React.Fragment>
    )
}

export default Home