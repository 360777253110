import React from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { apiUrl } from '../../constants/apiurl'

class ConfirmButton extends React.Component {

    state={}

    onSubmitClicked = () => {
        this.validate({...this.props.booking}, true)
    }

    submit = (booking) => {
        if (booking.payment.method==='credit/online') {
            this.props.stripe.createToken({type: 'card', email: booking.customer.email})
            .then( token => {
                if (token.error) {
                    this.setState({validateMsg: [token.error.message]})
                } else {
                    booking.payment.token = token.token.id
                    this.setState({loading: true})
                    this.send(booking)
                }

            })
        }
        else {
            this.setState({loading: true})
            this.send(booking)
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.validateMsg && this.state.validateMsg.length) {
            this.validate(newProps.booking)
        }
    }

    send = booking => {
        const URL = apiUrl + '/submitBookingV2'
        delete booking.assets
        delete booking.customer.isValid
        axios.post(URL, booking)
        .then(res => {
            this.props.history.push('/booking-submitted')
        }).catch(err => {
            console.log(err)
            this.setState({loading: false, validateMsg: ['There was a problem submitting your booking.  Please contact us if this error persists. ' + err.msg]})
        })
    }

    validate = (booking, trySubmit = false) => {
        const { bikes=[], bikeTransport={}, shuttles=[], luggage=[], customer={}, terms={} } = booking
        let msg = []
        

        // check bikes
        bikes.forEach((b,i) => {
            if (!b.cost && b.type ==='kids') msg.push('Please select an age for Rider ' + (i+1) )
            else if (!b.cost) msg.push('Please select a height for Rider ' + (i+1) )
        })

        // check bike transport
            if (bikes.length && (!bikeTransport.hasOwnProperty('cost') || bikeTransport.cost === undefined))
                msg.push('Please complete the LOCATION section for bike transport.')

        // check shuttles
        shuttles.forEach((s,i) => {
            if (!s.cost) msg.push('Please complete the options for Shuttle #' + (i+1))
        })

        // check bag transfer
        luggage.forEach((l,i) => {
            if (!l.cost) msg.push('Please complete the options for LUGGAGE TRANSFER #' + (i+1))
        })

        // contact details
        if (!customer.isValid) msg.push('Please complete the CONTACT details section.')

        // terms
        if (!terms.accepted) msg.push('You must read and agree to the terms of service and cancellations policy.')

        // submit
        if (!msg.length && trySubmit) {
            this.submit(booking)
        } else {
            this.setState({validateMsg: msg})
        }
    }


	render(){

        const { validateMsg=[], loading } = this.state
		return (
            <div className="booking-section">
                { !!validateMsg.length &&
                    <div className="alert alert-danger">
                        <h4 className="alert-heading">Whoops!  Some details are missing</h4>
                        { validateMsg.map((m,i) => 
                            <small key={i}><span>- {m}</span><br/></small>)
                        }
                    </div>
                }

                <button onClick={this.onSubmitClicked} disabled={loading} className='btn btn-block btn-lg btn-success'>
                    {loading && <span className='spinner-grow mr-2'></span>}
                    <span>Complete Booking</span>
                </button>
                
                <style jsx='true'>
                    {`
                 
                   
                    `}
                </style>
            </div>
		)
	}
}

export default withRouter(ConfirmButton)
