import React from "react";
import Cta from '../components/cta.js'
import { Link } from 'react-router-dom'
import rates  from '../constants/rentalRates'

class Bikes extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}
	
	render(){
		return (
	    	<React.Fragment>
	    		<div className='container pt-3'>
	    			<h1>Our Bikes</h1>
	    			<p>We offer a range of bikes to suit riders of all ages.  All our bikes are quality, late model bicycles from reputable brands such as Specialized, Avanti and Merida.  They are maintained to a very high standard and well suited for use on the Hauraki Rail Trail.  </p>
	    			<ul>
						<li>Helmet, map, and lock included</li>
						<li>Bike drop off service - means you don't have to mess about with getting your bike to the trail - we deliver it to where ever you plan to start your ride.</li>
						<li>Trail-side assistance - In the unlikely event you get a flat tire or need assistance on the trail, we are just a phone call away</li>
						<li>Flexible cancellation policy - Let us know at least 1 hour before the start time of your booking and we can reschedule or provide a refund. </li>
	    			</ul>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/ebike_.jpg' alt='E-Bikes for hire' width='400px'/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>E-Bikes ${rates.ebike}/day</h3>
	    					<p>Soak up the miles on our Merida e-bikes.  Featuring front suspension, hydraulic disc brakes, shimano motor.  Supplied with charger for multi-day hires.  Step-thru models also available.</p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>

					<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/comfort_sm.jpg' alt='Comfort for hire' width='400px'/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Comfort Bikes ${rates.adultBike}/day</h3>
	    					<p>Perfect for a leisurely cruise on the Rail Trail and suited to all fitness and experience levels.  Featuring a low entry frame and a very comfy seat!</p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/mtb_.jpg' alt='Mountain Bikes for hire' width='400px'/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Mountain Bikes ${rates.adultBike}/day</h3>
	    					<p>Capable on and off road, these bikes are perfectly suited for the rail trail.  Features front suspension and disc brakes.</p>
							<Link to ='/new-booking'>Book Online</Link>
						</div>
	    			</div>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/avanti.jpg' alt='Hybrid Bikes for hire' width='400px'/><br/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Step Thru Bikes ${rates.adultBike}/day</h3>
	    					<p>Low entry frame, front suspension, disc brakes, and comfy seat makes this bike a great option for your next adventure! </p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/kids.jpg' alt='Kids Bikes for hire' width='400px'/><br/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Kids Mountain Bikes ${rates.kidsBike}/day</h3>
	    					<p>Suitable for 7-12 yr olds.  We have a range of kids bikes with 20", 24" and 26" Wheels.  All have gears, front suspension, and disc brakes.</p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/trailer_md.jpg' alt='Trailer for hire' width='400px'/><br/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Trailer ${rates.trailer}/day</h3>
	    					<p>Seat up to two kids in this tow-behind trailer, or carry extra gear with ease.  Rated to 45kg, this is compatible with any of our adult bikes or eBikes.</p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>

	    			<div className='row border-top my-5 py-3'>
	    				<div className='col-auto order-2 order-md-1'>
	    					<img src='/images/child_seat.jpg' alt='Child Seats for hire' width='400px'/><br/>
	    				</div>
	    				<div className='col order-1 order-md-2'>
	    					<h3 className='text-primary'>Child Seat ${rates.childSeat}/day</h3>
	    					<p>We have rear mounted child seats available. Recommended for children 9 months up to 3 yrs old.</p>
							<Link to ='/new-booking'>Book Online</Link>
	    				</div>
	    			</div>


	    		</div>
				<Cta/>
	    	</React.Fragment>
	    )
	}
}

export default Bikes