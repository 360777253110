import React from "react";

export default class ShuttleView extends React.Component {

    formatTime = t => {
        let hh = t.substring(0,2)
        const mm = t.substring(2,4)
        const suffix = parseInt(hh) >= 12 ? 'pm' : 'am'
        if (parseInt(hh) > 12) hh = parseInt(hh) - 12 + ''
        return hh + ':' + mm + ' ' + suffix
    }

    render(){

        const { cost, pax, fromTown, toTown, date, time } = this.props.shuttle

		return (
            <div className="row border-bottom py-2" key={this.props.idx}>
                <div className="col"><img className='thumb' src='/images/shuttle_sm.jpg' alt=''/></div>
                <div className="col">
                    <b>Shuttle</b><br/>
                    From <em>{fromTown}</em> to <em>{toTown}</em><br/>
                    {date} {this.formatTime(time)}
                </div>

                <div className="col">
                    {pax} Passengers<br/>
                    <span className="text-success font-weight-bold">${cost}</span>
                </div>
            </div>
		)
	}
}