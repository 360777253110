import React from "react";
import moment from 'moment'
import BikeView from './bike-view'
import rates from '../../constants/rentalRates'

export default class SelectBikes extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
             mtb: this.getBikeTypeCount(props.bikes, 'mtb'),
             comfort: this.getBikeTypeCount(props.bikes, 'comfort'),
             hybrid_low: this.getBikeTypeCount(props.bikes, 'hybrid_low'),
             ebike: this.getBikeTypeCount(props.bikes, 'ebike'),
             kids: this.getBikeTypeCount(props.bikes, 'kids'),
        }
    }

    getSelectOptions = (all, available=all) => {
        let options = []
        for ( let i = 0; i <= all; i ++ ) {
            options.push(<option key={i} disabled = {i>available}>{i}</option>)
        }
        return options
    }

    onBikeQtyChange = ev => {
        let bikes = this.props.bikes || []
        const type = ev.target.id
        const newTotal = ev.target.value
        const oldTotal = this.getBikeTypeCount(bikes, type)

        if (newTotal > oldTotal) {
            // adding bikes
            for (let i = 0; i < newTotal-oldTotal; i ++) {
                bikes.push({type})
            }
        } else if (oldTotal > newTotal) {
            // removing bikes
            for (let i = 0; i < oldTotal-newTotal; i ++) {
                this.removeBike(bikes, type)
            }
        } else {
            return
        }
        this.setState({[type]: this.getBikeTypeCount(bikes, type)})
        this.props.onUpdate(bikes)
    }

    getBikeTypeCount = (bikes, type) => {
        let i = 0
        for (let b in bikes) {
            if (bikes[b].type === type) i ++
        }
        return i
    }

    removeBike = (bikes, type) => {
        let idx = 0
        for (let i = 0; i < bikes.length; i++) {
            if (bikes[i].type === type) idx = i
        }
        bikes.splice(idx, 1)
    }

    onUpdateBike = (idx, bike) => {
        let bikes = this.props.bikes
        bike.cost = this.getCost(bike)
        bikes[idx] = bike
        this.props.onUpdate(bikes)
    }

    getCost = (bike) => {
        if (!bike.height && !bike.age) return undefined //prevents form submission
        
        const { days } = this.props

        let rate 
        if (bike.type === 'ebike') rate = rates.ebike * days
        else if (bike.type === 'kids') rate = rates.kidsBike * days
        else rate = rates.adultBike * days    

        if (bike.trailer) rate += rates.trailer * days
        if (bike.seat) rate += rates.childSeat * days
        if (bike.panniers) rate += rates.panniers * days
        return rate
    }

    onDeleteBike = (idx) => {
        let bikes = this.props.bikes
        const type = bikes[idx].type
        bikes.splice(idx,1)
        this.setState({[type]: this.getBikeTypeCount(bikes, type)})
        this.props.onUpdate(bikes)
    }

    isTrailerAvailable = (idx) => {
        let qty = this.props.assets.trailer
        if (qty === 0) return false      

        this.props.bikes.forEach((b, i) => {
            if (idx !== i && b.trailer) qty--
        })

        return qty > 0
    }

    isSeatAvailable = (idx) => {
        let qty = this.props.assets.seat
        if (qty === 0) return false      

        this.props.bikes.forEach((b, i) => {
            if (idx !== i && b.seat) qty--
        })

        return qty > 0
    }

	render(){
        const { bikes=[], assets, days, from } = this.props
        const { mtb, comfort, ebike, kids, hybrid_low } = this.state

		return (
            <div className='booking-section'>
                <p><b>You are booking bikes for {days} day{days > 1 && 's'} starting {moment(from).format('dddd, MMM Do YYYY, LT')}</b></p>
                {/* eBikes */}
                <div className="form-inline my-2">
                    <div className="form-group">
                        <select id='ebike' value={ebike} className='form-control' onChange={this.onBikeQtyChange}>
                            {this.getSelectOptions(assets.ebike)}
                        </select>
                        <label className='ml-2'>Electric Bikes ${rates.ebike}/day</label>
                    </div>
                </div>
                {/* mtb */}
                <div className="form-inline my-2">
                    <div className="form-group">
                        <select id='mtb' value={mtb} className='form-control' onChange={this.onBikeQtyChange}>
                            {this.getSelectOptions(assets.mtb)}
                        </select>
                        <label className='ml-2'>Dual Sport/Mountain Bikes ${rates.adultBike}/day</label>
                    </div>
                </div>

                 {/* hybrid_low */}
                 <div className="form-inline my-2">
                    <div className="form-group">
                        <select id='hybrid_low' value={hybrid_low} className='form-control' onChange={this.onBikeQtyChange}>
                            {this.getSelectOptions(assets.hybrid_low)}
                        </select>
                        <label className='ml-2'>Dual Sport Step Thru Bikes ${rates.adultBike}/day</label>
                    </div>
                </div>
                
                {/* comfort */}
                <div className="form-inline my-2">
                    <div className="form-group">
                        <select id='comfort' value={comfort} className='form-control' onChange={this.onBikeQtyChange}>
                            {this.getSelectOptions(assets.comfort)}
                        </select>
                        <label className='ml-2'>Comfort Bikes ${rates.adultBike}/day</label>
                    </div>
                </div>
                {/* kids */}
                <div className="form-inline my-2">
                    <div className="form-group">
                        <select id='kids' value={kids} className='form-control' onChange={this.onBikeQtyChange}>
                            {this.getSelectOptions(assets.kids)}
                        </select>
                        <label className='ml-2'>Kids Bikes ${rates.kidsBike}/day</label>
                    </div>
                </div>

                <div className="row mx-1 full-width-row">

                { !bikes.length &&
                <div className='add-bikes-prompt'>Add bikes to your booking with the drop-down menus above.</div>
                }

                { bikes.map((b, i) =>
                    <BikeView 
                        key={i} 
                        idx={i} 
                        bike={b} 
                        days={days}
                        onUpdate={this.onUpdateBike}
                        isTrailerAvailable = {this.isTrailerAvailable(i)}
                        isSeatAvailable = {this.isSeatAvailable(i)}
                        onDelete={this.onDeleteBike}/>
                        )}
                </div>
                
                <style jsx='true'>
                    {`
                    
                    select option:disabled {
                        color: #ccc;
                    }

                    .add-bikes-prompt {
                        border: 1px dashed #ccc;
                        padding: 24px;
                        background: #fff;
                        color: #999;
                        max-width: 400px;
                    }

                    @media (max-width: 575px) {
                        .form-inline .form-control {
                          display: inline-block;
                          width: auto;
                          vertical-align: middle;
                        }
                      }
                      @media (max-width: 575px) {
                        .form-inline .form-group {
                          display: inline-block;
                          margin-bottom: 0;
                          vertical-align: middle;
                        }
                      }
                    `}
                </style>
            </div>
		)
	}
}
