import React from "react";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import { apiUrl } from '../../constants/apiurl'
import axios from 'axios'

const times = ['', 'TBA', '8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm']
const times_ = ['', 'TBA', '0800', '0815','0830','0845','0900','0915','0930','0945','1000','1015','1030','1045','1100','1115','1130','1145','1200','1215','1230','1245','1300','1315','1330','1345','1400','1415','1430','1445','1500','1515','1530','1545','1600','1615','1630','1645','1700','1715','1730','1745','1800']

export default class SelectDates extends React.Component {

    state = { from: undefined, to: undefined, fromTime: '', toTime:'', isMultiDay: false, loading: false}

    handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) return

        if (this.state.isMultiDay) {
            const range = DateUtils.addDayToRange(day, this.state);
            this.setState(range)
        }

        else {
            this.setState({from: modifiers.selected ? undefined : day, to: modifiers.selected ? undefined : day  })
        }
    }

    onFromTimeChange = (ev) => {
        this.setState({fromTime: ev.target.value})
    }

    onToTimeChange = (ev) => {
        this.setState({toTime: ev.target.value})
    }

    handleClearSelection = () => {
        this.setState({from: undefined, to: undefined})
    }

    handleRadioChange = ev => {
        if (ev.target.id === 'singleDay') {
            this.setState({ isMultiDay: false, to: undefined})
        }

        else this.setState({isMultiDay: true})
    }

    onDatesSelected = () => {
		window.scrollTo(0, 0)
        const { from, to, toTime, fromTime } = this.state
        this.setState({loading: true})
        const bookingDates = {
            from: moment(from).format('YYYYMMDD') + 'T' + (fromTime === 'TBA' ? '0759' : fromTime),
            to: moment(to).format('YYYYMMDD') + 'T' + (toTime === 'TBA' ? '1801' : toTime),
            days: moment(to).diff(moment(from),'days') + 1,
        }

        const url = '/getAvailabilityV2?start='
            + bookingDates.from.split('T')[0] 
            + '&finish='
            + bookingDates.to.split('T')[0]

        axios.get(apiUrl + url)
            .then( res => {
                this.props.onDatesSelected( bookingDates, res.data )
            })
            .catch( err => {
                this.setState({loading: false})
                alert('Sorry there was an error.  Please try again or call us for assistance.')
            })


        setTimeout( () => {
            //this.setState({loading: false})
            //this.props.onDatesSelected( bookingDates )
        }, 1000 )

    }

	render(){
       const { from, to, fromTime, toTime, isMultiDay, loading } = this.state
       const modifiers = { start: from, end: to }
       const dateFormat = 'dddd, MMM Do YYYY'

		return (
            <>
                {loading ? 
                <div className="text-center">
                    <hr/>
                    <h5>Just a moment..</h5>
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                
                :<div style={{border: '1px solid #eee', padding: 12}}>
                    <div className='booking-header'>
                        <h5>Choose Booking Date</h5>
                    </div>
                
                    <div className="row">
                        <div className="col-12 col-md-auto text-center">
                            <DayPicker
                                className={ isMultiDay ? "Selectable" : '' }
                                selectedDays={ isMultiDay ? [from, { from, to }] : from}
                                modifiers={modifiers}
                                onDayClick={this.handleDayClick}
                                disabledDays={{before: new Date()}}/>
                            <br/>
                            <button className='btn btn-link' onClick={this.handleClearSelection}>Clear Selection</button>
                        </div>
                        <div className="col-12 col-md-6 px-3 px-sm-5">
                            <hr className='d-block d-md-none'/>
                            <h5>HIRE DURATION</h5>
                            <div className="form-check">
                                <input type="radio" onChange={this.handleRadioChange} className='form-check-input' id='singleDay' checked={!isMultiDay}/>
                                <label className='form-check-label' htmlFor='singleDay'>Single Day Hire, or</label>
                            </div>
                            <div className="form-check">
                                <input type='radio' onChange={this.handleRadioChange} className='form-check-input' id='multiDay' checked={isMultiDay}/>
                                <label className='form-check-label' htmlFor='multiDay'>Multi Day Hire</label>
                            </div>

                            <h5>START/FINISH TIME</h5>
                            <div className="form-inline mt-1">
                                <div className="form-group">
                                    <label htmlFor="fromTime"><b>Start Time</b></label>
                                    <select value={fromTime} onChange={this.onFromTimeChange} name="fromTime" id="fromTime" className="ml-3 form-control">
                                        { times.map( (t, i) => 
                                            <option key={i} value={times_[i]}>{t}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-inline mt-1">
                                <div className="form-group">
                                    <label htmlFor="toTime"><b>Finish Time</b></label>
                                    <select value={toTime} onChange={this.onToTimeChange} name="toTime" id="toTime" className="ml-3 form-control">
                                        { times.map( (t, i) => 
                                            <option key={i} value={times_[i]}>{t}</option>)}
                                    </select>
                                </div>
                            </div>

                            <hr/>

                            { isMultiDay ?
                            <p className='mt-3'>
                                {!from && !to && <b>Please select the first day.</b>}
                                {from && !to && <b>Please select the last day.</b>}
                                {from && to && !fromTime && <b>Please select a start time.</b>}
                                {from && to && fromTime && !toTime && <b>Please select a finish time.</b>}
                                {from && to && fromTime && toTime && 
                                    <>
                                        <b>{moment(from).format(dateFormat)}</b> {fromTime} - 
                                        <b> {moment(to).format(dateFormat)}</b> {toTime}
                                        <em> ({moment(to).diff(moment(from), 'days') +1} days)</em>
                                    </>}
                            </p>

                            :<p className='mt-3'>
                                {!from && <b>Please select a date.</b>}
                                {from && !fromTime && <b>Please select a start time.</b>}
                                {from && fromTime && !toTime && <b>Please select a finish time.</b>}
                                {from && fromTime && toTime && 
                                    <>
                                        <b>{moment(from).format(dateFormat)}</b><br/>
                                        <em>{fromTime} - {toTime}</em>
                                    </>}
                            </p>
                            }

                            { fromTime && toTime && ((isMultiDay && from && to) || (!isMultiDay && from)) &&
                                <button onClick={this.onDatesSelected} className='btn btn-primary btn-block'>Check Availability</button>
                            }
                        </div>
                    </div>
                </div>
                }
                <style jsx='true'>
                    {`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                          }
                          .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                          }
                          .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                          }
                          .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                          }

                          
                        }
                      
                    `}
                </style>
            </>
		)
	}
}
