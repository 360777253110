import React from "react";
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa/";
import moment from 'moment'

class RouteMatch extends React.Component {

    componentDidMount() {
        this.init(this.props.trip.date)
    }

    init = date => {
        let d = moment(date, 'YYYYMMDD').startOf('week')
        let week = []
        for (let i =0; i < 7; i++) {
            week.push({
                d: d.format('YYYYMMDD'),
                dt: d.format('ddd D MMM')
            })
            d.add(1, 'days')
        }
        this.date = date
        this.setState({week, activeTab: date})
    }
    
    state = {activeTab: ''}

    setTab = t => {
        //this.setState({activeTab: t})
        this.date = t;
        let trip = {...this.props.trip}
        trip.date = t
        this.props.update({trip: trip})
    }

    componentWillReceiveProps(newProps) {
        if (newProps.trip.date !== this.date) {
            this.init(newProps.trip.date)
        }
    }

    changeWeek = n => {
        const d = moment(this.date, 'YYYYMMDD').add(n, 'week').format('YYYYMMDD')
        let trip = {...this.props.trip}
        trip.date = d
        this.props.update({trip:trip}) 
    }

    render(){
        const activeTab = this.date
        const { week=[] } = this.state
        const { result } = this.props
        return (
            <div className="card mt-5 mb-5">
                <div className="card-header"><h5>Shuttles</h5></div>
                <div className="card-body">
                    <Nav tabs justified>
                            <NavItem><NavLink onClick={()=>this.changeWeek(-1)}><FaChevronLeft/></NavLink></NavItem>
                            {week.map( (d,i) =>
                                <NavItem key={i}>
                                    <NavLink
                                        className={activeTab === d.d ? 'active text-primary' : 'text-secondary'}
                                        onClick={()=>this.setTab(d.d)}>
                                        {d.dt}
                                    </NavLink>
                                </NavItem>
                            )}
                            <NavItem><NavLink onClick={()=>this.changeWeek(1)}><FaChevronRight/></NavLink></NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <h3>{result}</h3>
                        {console.log('hey')}
                    </TabContent>
                    {/*
                    <TabContent activeTab={activeTab}>
                        {week.map((d,i) =>
                            <TabPane tabId={d.d} key={i}>
                                <div className="list-group list-group-flush">
                                    <a href="/#" className="list-group-item list-group-item-action">
                                        <h5>Waihi - Paeroa</h5>
                                        <p>{console.log('hey')}s</p>
                                        <p>{result}</p>
                                    </a>
                                    <a href="/#" className="list-group-item">
                                        <h5>Waihi - Paeroa</h5>
                                        <p>Departs 9am, stops at Waikino, Karangahake.</p>
                                    </a>
                                </div>
                            </TabPane>
                        )}
                            <TabPane tabId=''>
                                <div className="text-center my-5"><img src='/images/spinner.svg' alt='loading..'/></div>
                            </TabPane>
                    </TabContent>
                        */}
                  </div>
	    		
				<style jsx='true'>
                    {`
                   
                   `}
				</style>
            </div>
	    )
	}
}
export default RouteMatch