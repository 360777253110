import React from "react";
import { CardElement } from 'react-stripe-elements'

export default class Payment extends React.Component {

    onChange = ev => {
        const { payment, onUpdate } = this.props
        payment.method = ev.target.id
        onUpdate({payment})
    }

	render(){

        const { payment } = this.props

		return (
            <div className="col-12 col-lg-4">
                <h5>Payment</h5>
                <hr/>
                <b>Payment Options</b>
                
                <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'credit/online'} onChange={this.onChange} id="credit/online"/>
                    <label htmlFor='credit/online'>Credit Card</label>
                </div>
                
                { payment.method==='credit/online' && <>
                <div className='cardElement-container' >
                    <div className="form-group">
                        <label><small><b>Card Details</b></small></label>
                        <CardElement/>
                    </div>
                    <small className='text-secondary'><em>Your card will be only charged once your booking has been reviewed and confirmed.</em></small>
                </div>
                <br/>

                <small><b>Secure Payment</b></small><br/>
                <img src='/images/cc.svg' alt='Credit Cards Accepted' width='225px'/>
                <hr/>
                </>}

               <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'cash'} id='cash' onChange={this.onChange}/>
                    <label htmlFor='cash'>Cash</label>
                </div>
                <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'credit/eftpos'} onChange={this.onChange} id="credit/eftpos"/>
                    <label htmlFor='credit/eftpos'>Eftpos</label>
                </div>
                <div className="form-check">
                    <input className='form-check-input' type='radio'id="bank" checked={payment.method==='bank'} onChange={this.onChange} />
                    <label htmlFor='bank'>Bank Transfer</label>
                </div>
                <hr/>
                <p style={{fontSize: '0.9em'}}>A human will check your booking details and email you with any follow up questions.</p>
                <hr/>
                
                <style jsx='true'>
                 {`
                    .cardElement-container {
                        max-width: 400px;
                        padding: 12px 0 0 0;
                    }
                    .pay-logos {
                        padding: 10px 0;
                    }

                    .pay-logos img {
                        margin-right: 3%
                    }
                
                   
                `}
                </style>
            </div>
		)
	}
}

