import React from "react";
import { FaTimes, FaPlus } from "react-icons/fa/";
//import { TOWNS, TOWNS_DISPLAY, getPrice } from '../../components/shuttle-prices'
import { STATIONS_VIEW, getPrice } from "../shuttle-calc";
const times = ['', 'TBA', '8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm']
const times_ = ['', 'TBA', '0800', '0815','0830','0845','0900','0915','0930','0945','1000','1015','1030','1045','1100','1115','1130','1145','1200','1215','1230','1245','1300','1315','1330','1345','1400','1415','1430','1445','1500','1515','1530','1545','1600','1615','1630','1645','1700','1715','1730','1745','1800']

export default class AddShuttle extends React.Component {

    addShuttle = () => {
        const { onUpdate, shuttles } = this.props
        shuttles.push(
            {
                date: this.props.dates[0], 
                pax: this.props.riders <=10 ? this.props.riders : 10,
            })
        onUpdate(shuttles)
    }

    onDelete = idx => {
        const { onUpdate, shuttles } = this.props
        shuttles.splice(idx, 1)
        onUpdate(shuttles)
    }

    onChange = ev => {
        const { onUpdate, shuttles } = this.props
        const key = ev.target.id.split('.')[0]
        const idx = ev.target.id.split('.')[1]
        const val = ev.target.value
        shuttles[idx][key]=val
        const { pax = 0, fromTown = '', toTown = '', time='' } = shuttles[idx]
        shuttles[idx].cost = time ? getPrice({pax, start: fromTown, finish: toTown}) : undefined
        onUpdate(shuttles)
    }

	render(){

        const { shuttles=[], dates } = this.props

		return (
            <div className="booking-section ">
                <div className="full-width-row">
                    {shuttles.map((s, idx) =>
                    <div className="card mb-4" key={idx}>
                        <button onClick={()=>this.onDelete(idx)}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>
                        <div className="bike-view-header">
                            <div className='bike-view-header-title'><b>Passenger Shuttle #{idx+1}</b></div>
                        </div>

                        <div className="card-body">
                            <div className="form-row">
                                <div className="col-12 col-sm-auto">
                                    <div className="form-group">
                                        <label className="form-label"><b>Date</b></label>
                                        <select value={s.date || ''} className="form-control" id={'date.'+idx} onChange={this.onChange}>
                                            {dates.map((d,i) =>
                                                <option key={i}>{d}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-auto">
                                    <div className="form-group">
                                        <label className="form-label"><b>Time</b></label>
                                        <select value={s.time || ''} className="form-control" id={'time.'+idx} onChange={this.onChange}>
                                            {times.map((t,i) =>
                                                <option key={i} value={times_[i]}>{t}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-auto">
                                    <div className="form-group">
                                        <label className="form-label"><b>Passengers</b></label>
                                        <select value={s.pax ||''} className="form-control" id={'pax.'+idx} onChange={this.onChange}>
                                            <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option>
                                            <option>6</option><option>7</option><option>8</option><option>9</option><option>10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className="form-label"><b>From</b></label>
                                        <select value={s.fromTown || ''} className="form-control" id={'fromTown.'+idx} onChange={this.onChange}>
                                            <option></option>
                                            {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="form-group">
                                        <label className='d-none d-md-block form-label'>&nbsp;</label>
                                        <input value={s.fromAddress || ''} className="form-control" placeholder='Address' id={'fromAddress.'+idx} onChange={this.onChange}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className="form-label"><b>To</b></label>
                                        <select value={s.toTown || ''} className="form-control" id={'toTown.'+idx} onChange={this.onChange}>
                                            <option></option>
                                            {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="form-group">
                                        <label className='d-none d-md-block form-label'>&nbsp;</label>
                                        <input value={s.toAddress || ''} className="form-control" placeholder='Address' id={'toAddress.'+idx} onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            {   !s.fromTown ? <p className='text-danger'>* Please select a pickup location</p>
                                : !s.toTown ? <p className='text-danger'>* Please select a destination location</p>
                                : s.toTown === s.fromTown ? <p className='text-danger'>* Start and Finish cannot be the same</p>
                                : !s.time ? <p className='text-danger'>Please select a pickup time</p>
                                : <p><b className='text-success'>Price: NZD${s.cost}</b></p>
                            }
                        </div>
                    </div>
                    )}

                    <div className="add-shuttles d-flex align-items-center" onClick={this.addShuttle}>
                        <FaPlus className='mr-3'/> 
                        {!!shuttles.length 
                        ? <span> Click to add another Shuttle</span>
                        : <span> Click to add a Passenger Shuttle</span>}
                    </div>
                </div>
                
                <style jsx='true'>
                    {`
                    .add-shuttles {
                        padding: 12px;
                        border: 1px dashed #999;
                        background: #eee;
                    }

                    .add-shuttles:hover {
                        cursor:pointer;
                    }
                  
                    `}
                </style>
            </div>
		)
	}
}
