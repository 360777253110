import React from "react";

const RailTrail = props => {
	return (
  	<React.Fragment>
  			<div className='container-fluid'>
				<div className='row'>
		  			<div className='col-12 col-md-6 px-0 order-md-2'>
		  				<div className='p-5 bg-color-1'>
			  				<h2 className='info-title'>The Hauraki Rail Trail</h2>
			  				<p>The Hauraki Rail Trail is an easy grade one trail in NZ's north island spanning over 150km of beautiful countyside and fascinating history.</p>
							<p><b>Adventure Bike Hire</b> is based in the gold mining town of Waihi.  Right on section C - the most popular part of the trail encompassing the stunning Karangahake Gorge.</p>
							<p>We can provide bikes anywhere on the trail although positioning charges may apply for some areas.</p>

						</div>	  				
		  			</div>
		  			<div className='col-12 col-md-6 px-0 order-md-1 bg-image-1'>
		  			</div>
		  		</div>
		  	</div>
   		<style jsx='true'>
	    	{`
	    		.bg-image-1 {
	    			background: url('/images/intro-pic.jpg');
	    			background-size: cover;
	    			background-position: center;
	    			min-height: 350px;
	    		}

		        .bg-color-1 {
	    			background: #fff;
	    			color: #231f20;
	    			height:100%;
	    		}

    		`}</style>
    	</React.Fragment>
    )
}

export default RailTrail