import React from "react";
import { withRouter } from 'react-router'
import Calendar from '../components/booking_v3/calendar'
import Progress from '../components/booking_v3/progress'
import ActionView from '../components/booking_v3/actions-view'
import BikeView from '../components/booking_v3/bike-view'
import ShuttleView from '../components/booking_v3/shuttle-view'
import LuggageView from '../components/booking_v3/luggage-view'
import TourView from '../components/booking_v3/tour-view'
import BikeTransport from '../components/booking_v3/bike-transport'
import { apiUrl } from '../constants/apiurl'
import axios from 'axios'
import moment from 'moment'
import { tours } from '../constants/tours'
import { FaCaretLeft } from "react-icons/fa/";
import TrailStatus from '../components/booking_v3/trail-status'


class NewBooking extends React.Component {

    
    constructor(props) {
        super(props)
        const params = new URLSearchParams(props.location.search)
        this.state = this.initDates(props.location.state) || {...tours[params.get('tour')]}  
        this.manageScroll = this.manageScroll.bind(this)
    }

    initDates = s => {
        if (!s) return null
        let a = s.from.split('T')
        s.from = a[0]
        s.from_time = a.length === 2 ? a[1] : null
        let b = s.to.split('T')
        s.to = b[0]
        s.to_time = b.length === 2 ? b[1] : null
        return s
    }
    
	componentDidMount() {
		window.scrollTo(0, 0)
        this.header = document.getElementById("header");
        window.addEventListener('scroll', this.manageScroll)
        if (this.state.id) this.getAvailability()
	}

    componentWillUnmount() {
        window.removeEventListener('scroll', this.manageScroll)
    }

    manageScroll() {
         
        if (!this.state.scrollFixed && window.scrollY >= this.header.offsetHeight) {
            this.setState({scrollFixed: true})
        } else if (this.state.scrollFixed && window.scrollY < this.header.offsetHeight) {
            this.setState({scrollFixed: false})
        }
    }

    update = (json, mustSyncAvailability) => {
        if (json.from && json.to) json.days = this.getDayCount(json.from, json.to)

        this.setState({...json}, () =>{
            if (mustSyncAvailability) {// dates updated
                if (!this.state.from) {
                    this.setState({availability: undefined, syncingAvailability: false})
                    this.cancelToken.cancel()
                }
                else this.setState({syncingAvailability: true, availability: undefined}, this.getAvailability())
            }
        })
    }

    getAvailability = () => {

        if (this.cancelToken) this.cancelToken.cancel()
        this.cancelToken = axios.CancelToken.source()

        const { from, to } = this.state
        if (!from || !to) return
        const _from = moment(from).format('YYYYMMDD')
        const _to = moment(to).format('YYYYMMDD')
        axios.get(apiUrl + '/getAvailabilityV3?start=' + _from + '&finish=' +_to, 
            {
                cancelToken: this.cancelToken.token
            })
            .then( res => {
                console.log('refreshed availability')
                this.setState({syncingAvailability: false, availability: res.data}, this.validateCartAfterAvailabilityChange)
            })
            .catch (err => {})
    }

    validateCartAfterAvailabilityChange = () => {
        const { bikes=[], } = this.state
        const availability = JSON.parse(JSON.stringify(this.state.availability))
        let updated = false
        bikes.forEach( (r, idx) => {
            if (r.type && r.size) {
                if (availability[r.type][r.size] > 0){
                    availability[r.type][r.size] --
                } else {
                    delete bikes[idx].size
                    bikes[idx].validateError = 'Select a Bike Size'
                    updated = true
                }
            }
        })

        if (updated) this.setState({bikes})
    }

    getDayCount = (from, to) => {
        return 1+ moment(to).diff(moment(from), 'days') || null
    }

	render(){

        const { from, to, from_time, to_time, fixedNumberDays, bikes=[], shuttles=[], luggage=[], availability, syncingAvailability, packagePrice, tourDetails, scrollFixed, datesLockedIn, bikeTransport={} } = this.state
        const days = this.getDayCount(from, to)
        return (
        <>
            <TrailStatus/>
            <div className='container-fluid d-none d-md-block'>
                <div className='row my-4' >
                    <div className='col-12 col-md-6 col-lg-8 col-xl-9 order-md-first' style={{minHeight:600}}>
                        <div className='row'>

                            {!tourDetails && <ActionView 
                                bikes={bikes} 
                                shuttles={shuttles}
                                luggage={luggage}
                                update={this.update}
                            />}

                            {!!tourDetails && 
                                <TourView 
                                    tourDetails={tourDetails}
                                    bikes={bikes}
                                    update={this.update}
                                />}
                            { bikes.map(( bike, idx) => 
                                <BikeView
                                key={idx}
                                idx={idx}
                                bikes={bikes}
                                bike={bike}
                                days={days} 
                                update={this.update}
                                availability={availability}
                                packagePrice={packagePrice}
                                syncingAvailability={syncingAvailability}
                            />)}

                            { !!bikes.length && !tourDetails && 
                                <BikeTransport
                                    bikeTransport={bikeTransport}
                                    from_time={from_time}
                                    to_time={to_time}
                                    update = {this.update}
                            
                            />}

                            { shuttles.map(( shuttle, idx) => 
                                <ShuttleView
                                key={idx}
                                idx={idx}
                                shuttle={shuttle}
                                shuttles={shuttles}
                                from={from}
                                to={to} 
                                days={days}
                                update={this.update}
                            />)}

                            { luggage.map(( l, idx) =>
                                <LuggageView 
                                    key={idx}
                                    idx={idx}
                                    luggage={luggage}
                                    l={l}
                                    from={from}
                                    to={to}
                                    days={days}
                                    update={this.update}
                                />
                            )}
                           
                        </div>

                    </div>
                    <div className={'bg-white col-12 col-md-6 col-lg-4 col-xl-3 order-first border-md-left' + (scrollFixed ? ' _sticky' : '')}>
                        <Calendar 
                            update={this.update}
                            from={from}
                            to={to}
                            days={days}
                            fixedNumberDays={fixedNumberDays}/>

                        <Progress
                            className='d-none d-md-block'
                            state={this.state}
                            />
                    </div>
                </div>
              
            </div>
            {/* SMALL SCREENS */}
            <div className="container d-block d-md-none">
                
                {!datesLockedIn ? <>
                <Calendar 
                    update={this.update}
                    from={from}
                    to={to}
                    days={days}
                    fixedNumberDays={fixedNumberDays}/>

                <button 
                    className={ "btn btn-block btn-lg mt-5 " + (from && to ? 'btn-primary' : 'btn-outline-secondary')} 
                    disabled={!from || !to}
                    onClick={()=>this.setState({datesLockedIn: true})}
                    >Continue
                </button>
                </>
                
                :<>
                <div className="text-primary small link mt-2" onClick={()=>this.setState({datesLockedIn: false})}><FaCaretLeft/>   Select a different date</div>
                
                {!tourDetails && <ActionView 
                    bikes={bikes} 
                    shuttles={shuttles}
                    luggage={luggage}
                    update={this.update}
                />}

                {!!tourDetails && 
                    <TourView 
                        tourDetails={tourDetails}
                        bikes={bikes}
                        update={this.update}
                    />}
                { bikes.map(( bike, idx) => 
                    <BikeView
                    key={idx}
                    idx={idx}
                    bikes={bikes}
                    bike={bike}
                    days={days} 
                    update={this.update}
                    availability={availability}
                    packagePrice={packagePrice}
                />)}

                { !!bikes.length && !tourDetails && 
                    <BikeTransport
                    bikeTransport={bikeTransport}
                    update = {this.update}
                    from_time={from_time}
                    to_time={to_time}
                />}

                { shuttles.map(( shuttle, idx) => 
                    <ShuttleView
                    key={idx}
                    idx={idx}
                    shuttle={shuttle}
                    shuttles={shuttles}
                    from={from}
                    to={to} 
                    days={days}
                    update={this.update}
                />)}

                { luggage.map(( l, idx) =>
                    <LuggageView 
                    key={idx}
                    idx={idx}
                    luggage={luggage}
                    l={l}
                    from={from}
                    to={to}
                    days={days}
                    update={this.update}
                />)}
                
                <Progress
                    state={this.state}
                    />
                </>    
            }
            </div>
        </>
		)
	}
}

export default withRouter(NewBooking)