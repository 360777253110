import React from "react";
import ReactDOM from 'react-dom';
import { FaTimes, FaCheck } from "react-icons/fa/";
import moment from 'moment'
import { STATIONS_VIEW, SUGGESTADDRESS, getPrice } from "../shuttle-calc";
const times = ['', '8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm']
const times_ = ['', '0800', '0815','0830','0845','0900','0915','0930','0945','1000','1015','1030','1045','1100','1115','1130','1145','1200','1215','1230','1245','1300','1315','1330','1345','1400','1415','1430','1445','1500','1515','1530','1545','1600','1615','1630','1645','1700','1715','1730','1745','1800']
const dateFormat = 'ddd Do MMM YYYY'

export default class ShuttleView extends React.Component {
    constructor(props) {
        super(props)
        const { shuttles, idx, update} = props
        shuttles[idx] = this.validate(shuttles[idx])
        update({shuttles})
    }

    componentDidMount() {
        const bodyY = document.body.getBoundingClientRect().top
        var yPos = ReactDOM.findDOMNode(this).getBoundingClientRect().top
        if (yPos !== 0) window.scrollTo({left: 0, top: yPos-bodyY-24, behavior: 'smooth'})
    }

    componentDidUpdate() {
        const { from, to, shuttles, idx, update } = this.props
        if (shuttles[idx].date) {
            let d = moment(shuttles[idx].date, dateFormat)

            if (!from || !to || d.isBefore(from) || d.isAfter(to)) {
                delete shuttles[idx].date
                shuttles[idx] = this.validate(shuttles[idx])
                update({shuttles})
            }
        } else if (from && moment(from).isSame(moment(to) )) {
            shuttles[idx].date = moment(from).format(dateFormat)
            shuttles[idx] = this.validate(shuttles[idx])
            update({shuttles})
        }
    }
    
    onChange = ev => {
        const { idx, update, shuttles } = this.props
        const key= ev.target.id
        shuttles[idx][key] = ev.target.value
        shuttles[idx] = this.validate(shuttles[idx])
        update({shuttles})
    }

    validate = s => {
        delete s.validateError
        delete s.cost
        if (this.props.days > 1 && !s.date) s.validateError = 'Select a Date'
        if (!s.fromTown) s.validateError = 'Select a departure point'
        else if (!s.toTown) s.validateError = 'Select a destination'
        else if (s.fromTown === s.toTown) s.validateError = 'Departure & Destination cannot be the same'
        else if (!s.pax) s.validateError = 'Select how many passengers'
        else if (!s.time) s.validateError = 'Select a departure time'
        else s.cost = getPrice(s)
        return s
    }

    onDelete = () => {
        const { idx, shuttles, update } = this.props
        shuttles.splice(idx,1) 
        update({shuttles})
    }

    getDateOptions = (f, t) => {
        if (!f || !t) return []
        let from = moment(f)
        let to = moment(t)
        let options = [ from.format(dateFormat) ]
        while( from.isBefore(to) ) {
            from.add(1, 'days')
            options.push(from.format(dateFormat))
        }

        return options
    }

    getSuggestAddress = town => {
        const idx = STATIONS_VIEW.indexOf(town)
        if (idx === -1) return 'Address'
        return SUGGESTADDRESS[idx]
    }

	render(){
        const { from, to, shuttle } = this.props
        const dateOptions = this.getDateOptions(from, to)
        const suggestFromAddress = this.getSuggestAddress(shuttle.fromTown)
        const suggestToAddress = this.getSuggestAddress(shuttle.toTown)

        return (
            <div className="col-12 col-lg-6 col-xl-4 my-3 bike-view-col">
                <div className="card">
                    <button onClick={this.onDelete}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>
                    <div className="bike-view-header">
                        <div className='bike-view-header-title'><b>Shuttle</b></div>
                    </div>
                    <img src='images/shuttle_small.jpg' alt="" className="card-img-bottom"/>
                    <div className="card-body">
                        <div className="card-text">
                          
                            {/* DATE */}
                            { dateOptions.length > 1 &&
                            <div className="form-group">
                                <label className="form-label"><b>Select Date</b></label>
                                <select value={shuttle.date || ''} onChange={this.onChange} id="date" className="form-control">
                                    <option></option>
                                    { dateOptions.map( (d, i) =>
                                        <option key={i} value={d}>{d}</option>)
                                    }
                                </select>
                            </div>
                            }

                            <div className="form-group">
                            {/* Departure Address */}
                                <label className="form-label"><b>From</b></label>
                                <select value={shuttle.fromTown || ''} className="form-control" id='fromTown' onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                </select>
                                <input value={shuttle.fromAddress || ''} className="mt-2 form-control" placeholder={suggestFromAddress} id='fromAddress' onChange={this.onChange}/>
                               
                            {/* Arrival Address */}
                                <label className="form-label mt-4"><b>To</b></label>
                                <select value={shuttle.toTown || ''} className="form-control" id='toTown' onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                </select>

                                <input value={shuttle.toAddress || ''} className="mt-2 form-control" placeholder={suggestToAddress} id='toAddress' onChange={this.onChange}/>
                            
                            {/* Passengers */}
                                <label className="form-label mt-4"><b>Passengers</b></label>
                                <select value={shuttle.pax ||''} className="form-control" id='pax' onChange={this.onChange}>
                                    <option></option><option>1</option><option>2</option><option>3</option><option>4</option><option>5</option>
                                    <option>6</option><option>7</option><option>8</option><option>9</option><option>10</option>
                                </select>

                            {/* Departure Time */}                                
                                <label className="form-label mt-4"><b>Departure Time</b></label>
                                <select value={shuttle.time || ''} className="form-control" id='time' onChange={this.onChange}>
                                    {times.map((t,i) =>
                                        <option key={i} value={times_[i]}>{t}</option>)}
                                </select>

                            </div>
                        </div>
                    </div>

                    { !!shuttle.validateError && <div className="alert alert-info mb-0 rounded-0">{shuttle.validateError}</div>}
                    { !shuttle.validateError && !!shuttle.cost && <div className="alert alert-success mb-0 rounded-0 lead"><FaCheck/><span className='ml-5'>Shuttle Cost: ${shuttle.cost}</span></div>}
                </div>

            <style jsx='true'>
                {`

                @media (max-width: 575px) {
                    .bike-view-col {
                        padding: 0px!important;
                    }
                }
                
                .bike-view-header {
                    background: #027ab7;
                    padding: 0.5em 1em;
                }
                
                .bike-view-delete-button {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    padding: 0;
                    border: 3px solid #fff;
                    padding-bottom: 5px;
                    box-shadow: none;
                }
                
                .bike-view-header-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                }

                .disabled-option {
                    color:#ddd;
                }
                
                `}
            </style>
        </div>
		)
	}
}
