const DISTANCES = 
    {
        kaiaua_miranda: 10,
        kaiaua_thames: 45,
        kaiaua_paeroa: 75,
        kaiaua_te_aroha: 95,
        kaiaua_matamata: 130,
        kaiaua_waihi: 95,
        kaiaua_waihi_beach: 105,

        miranda_thames :35,
        miranda_paeroa :65,
        miranda_te_aroha :85,
        miranda_matamata :120,
        miranda_waihi :85,
        miranda_waihi_beach :95,

        thames_paeroa : 30,
        thames_te_aroha : 50,
        thames_matamata : 85,
        thames_waihi : 50,
        thames_waihi_beach : 60,

        paeroa_te_aroha : 20,
        paeroa_matamata : 55,
        paeroa_waihi : 20,
        paeroa_waihi_beach : 30,

        te_aroha_matamata : 35,
        te_aroha_waihi : 40,
        te_aroha_waihi_beach : 50,

        matamata_waihi : 75,
        matamata_waihi_beach : 85,

        waihi_waihi_beach : 10,
    }

export const TOWNS = ['waihi_beach', 'waihi', 'paeroa', 'te_aroha', 'matamata', 'thames', 'miranda', 'kaiaua']
export const TOWNS_DISPLAY = ['Waihi Beach', 'Waihi', 'Paeroa', 'Te Aroha', 'Matamata', 'Thames', 'Miranda', 'Kaiaua']

const PRICE_PER_KM = 2
const PRICE_PER_KM_POSITIONING = 1.5
const BASE_CHARGE = 40
const PRICE_ADDITIONAL_PAX = 15

const getDistance = (from, to) => {
    return DISTANCES[from + '_' + to] 
        ? DISTANCES[from + '_' + to]
        : DISTANCES[to + '_' + from] 
}

const getPositioning = (from, to) => {
    if (from === 'waihi' || to === 'waihi') return 0
    const a = getDistance('waihi', from)
    const b = getDistance('waihi', to)
    return a > b ? b : a
}

export const getPrice = ( pax, from, to ) => {
    const distance = getDistance(from, to)
    if (distance === undefined ) return ''
    if (pax < 1) return ''
    
    const additional_pax = pax > 2 
        ? (pax - 2) * PRICE_ADDITIONAL_PAX    
        : 0

    const positioning = getPositioning(from, to)

    const shuttle_price = 
        distance * PRICE_PER_KM +
        BASE_CHARGE +
        additional_pax +
        positioning * PRICE_PER_KM_POSITIONING

    return Math.floor(shuttle_price)
}