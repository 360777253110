import React from "react";


export default class Contact extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
		window.gtag('event', 'conversion', {'send_to': 'AW-1024121856/XOABCKfq9dgBEIC4q-gD'});
		/*
		if (!document.getElementById('googleTagScript')) {
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.innerHTML = "window.gtag('event', 'conversion', {'send_to': 'AW-1024121856/XOABCKfq9dgBEIC4q-gD'}); console.log('script ran');";
			s.id = 'googleTagScript'
			document.body.appendChild(s);
		}
		*/
	}	

	render() {
		return (
	    	<React.Fragment>
	    		<div className='container pt-3'>
	    			<div className='row'>
	    				<div className='col-md-6'>
	    					<h1>Contact Us</h1>
	    					<h3 className='text-primary'>Hours</h3>
	    					<p>7 days, 8am-6pm</p>
	    					<h3 className='text-primary'>Phone</h3>
	    					<p>(027) 398 2866 (NZ)<br/>
	    					(+64) 27 398 2866 (International)</p>
	    					<h3 className='text-primary'>Email</h3>
	    					<a href='mailto:hello@adventurebikehire.co.nz'>hello@adventurebikehire.co.nz</a>
	    				</div>
	    				<div className='col col-md-6 pt-3'>
	    					<img className='w-100' src='/images/contact.jpg' alt='Adventure Bike Hire' />
	    				</div>
	    			</div>
	    		</div>
	    	</React.Fragment>
	    )
	}
}

