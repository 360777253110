import React from "react";
import { withRouter } from 'react-router-dom'

class BookingSubmitted extends React.Component {

    componentDidMount() {
		window.scrollTo(0, 0)
    }
    
	render(){

		return (
            <div className="container">
                <h1>Thanks..</h1>
                <p className='lead'>Your booking has been submitted sucessfully.  We're just checking the details and we'll get back to you within 24 hours with confirmation.</p>
                <p><b>If you have any questions or wish to check the status of your booking you can contact us on <em>027 398 2866</em></b></p>
                <br/><br/>
                <img src='/images/success.jpg' width='100%' alt=''/>
                <style jsx='true'>
                    {`
                 
                   
                    `}
                </style>
            </div>
		)
	}
}

export default withRouter(BookingSubmitted)
