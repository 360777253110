import React, {Component} from "react";
import { withRouter } from 'react-router'
import SelectDates from './select-dates'
import BookBikesHeader from './book-bikes-header'
import SelectBikes from './select-bikes'
import DropoffPickup from './dropoff-pickup'
import Shuttle from './shuttle'
import BagTransfer from './bag-transfer'
import Contact from './contact'
import Payment from './payment'
import Terms from './terms'
import ConfirmButton from './confirm'
import moment from 'moment'
import { publicKey } from '../../constants/stripekey'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'

class BookBikes extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getInitialState = () => {
        //window.scrollTo(0, 0)
        return {
            from: undefined,
            to: undefined,
            bikes: [],
            shuttles: [],
            bikeTransport: undefined,
            luggage: undefined,
            days: undefined,
            /*from: '20191101T1000',
            to: '20191102T1800',
            days: 2,
            bikes: [{type:'mtb'}],*/
            /*assets: {
                ebike: { all:4, available: 4, day_rate: 95, multi_day_rate: 90 },
                mtb: { all: 10, available: 10, day_rate: 40, multi_day_rate: 35 },
                comfort: {all: 6, available: 6, day_rate: 40, multi_day_rate: 40},
                kids: {all: 3, available: 3, day_rate: 30, multi_day_rate: 25},
                trailer: {all: 1, available: 1, day_rate: 25, multi_day_rate: 20},
                seat: {all: 1, available: 1, day_rate: 15, multi_day_rate: 15},
                panniers: {day_rate: 5},
            },*/
            assets: {},
            payment: {method: 'credit/online', total: 0}
        }
     }

    onDatesSelected = (d, assets) => {
        this.setState({...d, assets})
    }

    getDatesList = () => {
        let list = []
        let d = moment(this.state.from)
        for (let i = 0; i < this.state.days; i ++) {
            list.push(d.format('ddd Do MMM YYYY'))
            d = d.add(1,'day')
        }
        return list
    }

	render(){
        const { from, to, days, bikes=[], assets, shuttles=[], luggage=[], bikeTransport={}, customer={}, payment={}, terms={} } = this.state
        const { stripe } = this.props

		return (
            <div className="container">
                <h1>Book Bikes</h1>
                { !this.state.from 
                ? <SelectDates onDatesSelected={this.onDatesSelected}/>
                :<>
                    <BookBikesHeader from={from} to={to} days={days} back={()=>this.setState(this.getInitialState())} />
                    <SelectBikes assets={assets} bikes={bikes} days={days} from={from} onUpdate={bikes=>this.setState({bikes})} />
                    {!!bikes.length && <>
                        <DropoffPickup bikeTransport={bikeTransport} onUpdate={bikeTransport => this.setState({bikeTransport})}/>
                        <Shuttle dates={this.getDatesList()} riders={bikes.length} shuttles={shuttles} onUpdate={shuttles=>this.setState({shuttles})}/>
                        <BagTransfer dates={this.getDatesList()} luggage={luggage} onUpdate={luggage=>this.setState({luggage})}/>
                        <div className="booking-section">
                            <div className="row">
                                <Contact customer={customer} onUpdate={customer => this.setState({customer})}/>
                                <Payment payment={payment} booking={this.state} onUpdate={payment => this.setState({payment})}/>
                                <Terms terms={terms} onUpdate={terms => this.setState({terms})}/>
                            </div>
                        </div>
                        <ConfirmButton booking={this.state} stripe={stripe}/>
                    </>}
                </>
                }
                
                
                <style jsx='true'>
                    {`
                    .booking-header {
                        border-bottom: 1px solid #eee;
                        background: #f7f7f7;
                        margin: -12px;
                        padding: 4px 12px;
                    }

                    .booking-section {
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                        padding: 12px;
                    }

                    .full-width-row {
                        background: #f7f7f7;
                        margin: 12px -12px !important; 
                        border-top: 1px solid #eee;
                        border-bottom: 1px solid #eee;
                        padding: 12px; 
                    }
                    
                    `}
                </style>
            </div>
		)
	}
}


class Injector extends Component {
	constructor() {
    super()
    this.state = {stripe: null}
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(publicKey)})
    } else {
      const script = document.createElement('script')
      script.onload = () => this.setState({stripe: window.Stripe(publicKey)})
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script);
    }
  }

  render() {

  	const InjectedForm = injectStripe(BookBikes)
    return (
      <StripeProvider stripe={ this.state.stripe }>
        <Elements>
          <InjectedForm 
          	stripe={this.state.stripe} {...this.props}/> 
        </Elements>
      </StripeProvider>
    )
  }
}

export default withRouter(Injector)
