import React from "react";
import { CardElement } from 'react-stripe-elements'

export default class Payment extends React.Component {

    state={}
    
    onChange = ev => {
        const { payment, onUpdate } = this.props
        payment.method = ev.target.id
        onUpdate(payment)
    }

    componentDidMount(){
        this.componentWillReceiveProps(this.props)
    }

    componentWillReceiveProps(newProps) {
        const totals = this.getTotals()
        const { payment, onUpdate } = newProps
        if (payment.total !== totals.total) {
            payment.total = totals.total
            onUpdate(payment)
        }

        this.setState({totals})

    }

    getTotals = () => {
        let items = {
            bikes: this.getArrayTotal(this.props.booking.bikes),
            bikeTransport: (this.props.booking.bikeTransport && this.props.booking.bikeTransport.cost) || undefined,
            shuttle: this.getArrayTotal(this.props.booking.shuttles),
            luggage: this.getArrayTotal(this.props.booking.luggage),
        }
        let t = 0
        t += items.bikes || 0
        t += items.bikeTransport || 0
        t += items.shuttle || 0
        t += items.luggage || 0
        
        items.total = t
        return items
    }

    getArrayTotal = (item=[]) => {
        let t = 0
        item.forEach( i => {
            t += i.cost
        })
        return t || undefined
    }

    getBikeTransportTotal = bikeTransport => {
        return (bikeTransport && bikeTransport.cost) || undefined
    }

	render(){

        const { payment={} } = this.props
        const { totals = 0 } = this.state

		return (
            <div className="col-12 col-lg-4">
                <h5>Payment</h5>
                <p><b>Booking Summary</b></p>
                <div className="row">
                    <div className="col-auto">
                        {totals.bikes && <>Bike Hire:<br/></>}
                        {totals.bikeTransport && <>Bike Transport:<br/></>}
                        {totals.shuttle && <>Shuttle:<br/></>}
                        {totals.luggage && <>Bag Transfer:<br/></>}
                        <b>Total:</b>
                    </div>
                    <div className="col-auto">
                        {totals.bikes && <>${totals.bikes}<br/></>}
                        {totals.bikeTransport && <>${totals.bikeTransport}<br/></>}
                        {totals.shuttle && <>${totals.shuttle}<br/></>}
                        {totals.luggage && <>${totals.luggage}<br/></>}
                        {totals.total && <b>${totals.total}NZD</b>}
                    </div>
                </div>
                <hr/>
                <b>Payment Options</b>
                
                <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'credit/online'} onChange={this.onChange} id="credit/online"/>
                    <label htmlFor='credit/online'>Credit Card</label>
                </div>
                
                { payment.method==='credit/online' && <>
                <div className='cardElement-container' >
                    <div className="form-group">
                        <label><small><b>Card Details</b></small></label>
                        <CardElement/>
                    </div>
                    <small className='text-secondary'><em>Your card will be only charged once your booking has been reviewed and confirmed.</em></small>
                </div>
                <br/>

                <small><b>Secure Payment</b></small><br/>
                <img src='/images/cc.svg' alt='Credit Cards Accepted' width='225px'/>
                <hr/>
                </>}

               <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'cash'} id='cash' onChange={this.onChange}/>
                    <label htmlFor='cash'>Cash</label>
                </div>
                <div className="form-check">
                    <input className='form-check-input' type='radio' checked={payment.method === 'credit/eftpos'} onChange={this.onChange} id="credit/eftpos"/>
                    <label htmlFor='credit/eftpos'>Eftpos</label>
                </div>
                <div className="form-check">
                    <input className='form-check-input' type='radio'id="bank" checked={payment.method==='bank'} onChange={this.onChange} />
                    <label htmlFor='bank'>Bank Transfer</label>
                </div>
                <hr/>
                <p style={{fontSize: '0.9em'}}>A human will check your booking details and email you with any follow up questions.</p>
                <hr/>
                
                <style jsx='true'>
                 {`
                    .cardElement-container {
                        max-width: 400px;
                        padding: 12px 0 0 0;
                    }
                    .pay-logos {
                        padding: 10px 0;
                    }

                    .pay-logos img {
                        margin-right: 3%
                    }
                
                   
                `}
                </style>
            </div>
		)
	}
}

