import React from "react";
import moment from 'moment'
import BikeView from './booking-summary-bike-view'
import ShuttleView from './booking-summary-shuttle-view'
import LuggageView from "./booking-summary-luggage-view";
import BikeTransportView from './booking-summary-bike-transport-view'

export default class Summary extends React.Component {

    constructor(props) {
        super(props)
        this.state = { voucher: ''}
    }

    componentDidMount() {
        let total = 0
        const { bikes=[], shuttles=[], luggage=[], bikeTransport = {}} = this.props.booking
        bikes.forEach(b => {
            total += b.cost
        })

        shuttles.forEach(s => {
            total += s.cost
        })

        luggage.forEach(l => {
            total += l.cost
        })

        if (bikes.length) total += bikeTransport.cost || 0

        this.props.setTotal(total)
    }

    formatDate = () =>{
        var { from, to } = this.props.booking
        from = from.split('T')[0]
        to = to.split('T')[0]
        if (from === to) {
            return moment(from).format('ddd Do MMM YYYY')
        } else {
            return moment(from).format('ddd Do MMM YYYY') + ' to ' + moment(to).format('ddd Do MMM YYYY')
        }
    }

    onChange = ev => {
        this.setState({voucher: ev.target.value})
    }

    render(){
        const { voucher } = this.state
        const { bikes=[], shuttles=[], luggage=[], bikeTransport = {}, payment={}, tourDetails } = this.props.booking
		return (
            <div>
                <div className="small text-primary font-weight-bold border-bottom pb-3">Booking {!!tourDetails && <span>{tourDetails.tourTitle}</span>} for {this.formatDate()}</div>
                {bikes.map( (b, i) => <BikeView key={i} idx={i} bike = {b}/>)}
                {!tourDetails && !!bikes.length && <BikeTransportView bikeTransport={bikeTransport}/>}
                {shuttles.map( (s, i) => <ShuttleView key={i} idx={i} shuttle={s}/>)}
                {luggage.map( (l,i) => <LuggageView key={i} idx={i} luggage={l}/>)}


                <div className="row py-5" style={{background: '#f0f0f0'}}>
                    <div className="col-9 col-sm-6">
                        <div className="form-group">
                            <label className="form-label"><small><b>Discount Code or Voucher Number</b></small></label>
                            <div className="input-group">
                                <input value={voucher} onChange={this.onChange} id='voucher' className='form-control' />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button">Apply</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="row">
                            <div className="col text-right small">SubTotal</div>
                            <div className="col font-weight-bold">${payment.total}NZD</div>
                        </div>
                        <div className="row">
                            <div className="col text-right small">Discount</div>
                            <div className="col font-weight-bold">-</div>
                        </div>
                        <div className="row text-primary lead font-weight-bold">
                            <div className="col text-right">Total</div>
                            <div className="col">${payment.total}NZD</div>
                        </div>

                    </div>
                  
                </div>
            </div>
		)
	}
}