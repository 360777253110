import React from "react";
import ReactDOM from 'react-dom';
import { FaTimes, FaCheck } from "react-icons/fa/";
import moment from 'moment'
import { STATIONS_VIEW } from "../shuttle-calc";
const dateFormat = 'ddd Do MMM YYYY'

export default class LuggageView extends React.Component {
    constructor(props) {
        super(props)
        const { luggage, idx, update} = props
        luggage[idx] = this.validate(luggage[idx])
        update({luggage})
    }

    componentDidMount() {
        const bodyY = document.body.getBoundingClientRect().top
        var yPos = ReactDOM.findDOMNode(this).getBoundingClientRect().top
        window.scrollTo({left: 0, top: yPos-bodyY-24, behavior: 'smooth'})
    }

    componentDidUpdate() {
        const { from, to, luggage, idx, update } = this.props
        if (luggage[idx].date) {
            let d = moment(luggage[idx].date, dateFormat)

            if (!from || !to || d.isBefore(from) || d.isAfter(to)) {
                delete luggage[idx].date
                luggage[idx] = this.validate(luggage[idx])
                update({luggage})
            }
        } else if (from && moment(from).isSame(moment(to) )) {
            luggage[idx].date = moment(from).format(dateFormat)
            luggage[idx] = this.validate(luggage[idx])
            update({luggage})
        }
    }
    
    onChange = ev => {
        const { idx, update, luggage } = this.props
        const key= ev.target.id
        luggage[idx][key] = ev.target.value
        luggage[idx] = this.validate(luggage[idx])
        console.log(luggage[idx])
        update({luggage})
    }

    validate = s => {
        delete s.validateError
        delete s.cost
        if (this.props.days > 1 && !s.date) s.validateError = 'Select a Date'
        if (!s.fromTown) s.validateError = 'Select a pickup town'
        else if (!s.toTown) s.validateError = 'Select a drop off town'
        else if (s.fromTown === s.toTown) s.validateError = 'Departure & Destination cannot be the same'
        else s.cost = this.getPrice(s)
        return s
    }

    getPrice = s => {
        return 100
    }

    onDelete = () => {
        const { idx, luggage, update } = this.props
        luggage.splice(idx,1) 
        update({luggage})
    }

    getDateOptions = (f, t) => {
        if (!f || !t) return []
        let from = moment(f)
        let to = moment(t)
        let options = [ from.format(dateFormat) ]
        while( from.isBefore(to) ) {
            from.add(1, 'days')
            options.push(from.format(dateFormat))
        }

        return options
    }

	render(){
        const { from, to } = this.props
        const luggage = this.props.l
        const dateOptions = this.getDateOptions(from, to)

        return (
            <div className="col-12 col-lg-6 col-xl-4 my-3 bike-view-col">
                <div className="card">
                    <button onClick={this.onDelete}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>
                    <div className="bike-view-header">
                        <div className='bike-view-header-title'><b>Luggage Transfer</b></div>
                    </div>
                    <img src='images/luggage.jpg' alt="" className="card-img-bottom"/>
                    <div className="card-body">
                        <div className="card-text">
                          
                            {/* DATE */}
                            { dateOptions.length > 1 &&
                            <div className="form-group">
                                <label className="form-label"><b>Select Date</b></label>
                                <select value={luggage.date || ''} onChange={this.onChange} id="date" className="form-control">
                                    <option></option>
                                    { dateOptions.map( (d, i) =>
                                        <option key={i} value={d}>{d}</option>)
                                    }
                                </select>
                            </div>
                            }

                            <div className="form-group">
                            {/* Departure Address */}
                                <label className="form-label"><b>From</b></label>
                                <select value={luggage.fromTown || ''} className="form-control" id='fromTown' onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                </select>
                                <input placeholder='Address' value={luggage.fromAddress || ''} className="mt-2 form-control" id='fromAddress' onChange={this.onChange}/>
                               
                            {/* Arrival Address */}
                                <label className="form-label mt-4"><b>To</b></label>
                                <select value={luggage.toTown || ''} className="form-control" id='toTown' onChange={this.onChange}>
                                    <option></option>
                                    {STATIONS_VIEW.map(t => <option key={t} value={t}>{t}</option>)}
                                </select>

                                <input placeholder='Address' value={luggage.toAddress || ''} className="mt-2 form-control" id='toAddress' onChange={this.onChange}/>
                            
                            {/* Bags */}
                                <label className="form-label mt-4"><b>Bags</b></label>
                                <select value={luggage.bags ||''} className="form-control" id='bags' onChange={this.onChange}>
                                    <option></option><option>1</option><option>2</option><option>3</option><option>4</option><option>5</option>
                                    <option>6</option><option>7</option><option>8</option><option>9</option><option>10</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    { !!luggage.validateError && <div className="alert alert-info mb-0 rounded-0">{luggage.validateError}</div>}
                    { !luggage.validateError && !!luggage.cost && <div className="alert alert-success mb-0 rounded-0 lead"><FaCheck/><span className='ml-5'>Luggage Transfer Cost: ${luggage.cost}</span></div>}
                </div>

            <style jsx='true'>
                {`

                @media (max-width: 575px) {
                    .bike-view-col {
                        padding: 0px!important;
                    }
                }
                
                .bike-view-header {
                    background: #027ab7;
                    padding: 0.5em 1em;
                }
                
                .bike-view-delete-button {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    padding: 0;
                    border: 3px solid #fff;
                    padding-bottom: 5px;
                    box-shadow: none;
                }
                
                .bike-view-header-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                }

                .disabled-option {
                    color:#ddd;
                }
                
                `}
            </style>
        </div>
		)
	}
}
