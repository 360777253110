import React from "react";
import { FaExclamation } from "react-icons/fa/";

export default class TrailStatus extends React.Component {

	render(){

        return (
            <div className="alert alert-warning">
                <FaExclamation/><small>The section of Trail between Kopu and Waitakaruru is CLOSED for the forseeable future, due to works being undertaken by Waikato Regional Council.</small><br/>
                <a href="https://haurakirailtrail.co.nz" target="_blank" rel="noopener noreferrer"><small className="text-primary">For full status updates on the trail see haurakirailtrail.co.nz</small></a>
            </div>
		)
	}
}
