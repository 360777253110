import React from "react";
import { TOWNSDISPLAY } from "../../constants/towns";

export default class BikeTransportView extends React.Component {

    render(){

        const { startTown, finishTown, cost } = this.props.bikeTransport

		return (
            <div className="row border-bottom py-2">
                <div className="col"><img className='thumb' src='/images/bt.jpg' alt=''/></div>
                <div className="col">
                    <b>Bike Transport</b><br/>
                    Start at {TOWNSDISPLAY[startTown.split('.')[0]]}<br/>
                    Finish at {TOWNSDISPLAY[finishTown.split('.')[0]]}
                </div>

                <div className="col">
                    <span className="text-success font-weight-bold">${cost}</span>
                </div>
            </div>
		)
	}
}