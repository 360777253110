import React from "react";
import { FaPhone } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa/";
import { FaInstagram } from "react-icons/fa/";
import { FaFacebook } from "react-icons/fa/";
import { Link } from 'react-router-dom'

const Footer = props => {
	return (
    	<React.Fragment>
    		<section className='footer mt-5' style={{zIndex: 1}}>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-md-4 text-left pt-4'>
							<h5 className='lead'>Contact Us</h5>
							<a className='footer-link' href='tel:+64 27 398 2866'><FaPhone/>&nbsp;&nbsp; +64 27 398 2866</a>
							<a className='footer-link' href='mailto:hello@adventurebikehire.co.nz'><FaEnvelope/>&nbsp;&nbsp; hello@adventurebikehire.co.nz</a>
						</div>
						<div className='col-12 col-md-4 text-left pt-4'>
							<h5 className='lead'>Follow Us</h5>
							<a className='footer-link' href='https://instagram.com/adventurebikehire'><FaInstagram/> Instagram</a>
							<a className='footer-link' href='https://facebook.com/adventurebikehire'><FaFacebook/> Facebook</a>

						</div>
						<div className='col-12 col-md-4 text-left pt-4'>
							<h5 className='lead'>Quick Links</h5>
							<Link className='footer-link' to='/'>Home</Link>
                            <Link className='footer-link' to='/bikehire'>Bike Hire</Link>
							<Link className='footer-link' to='/shuttle'>Shuttle</Link>
                            <Link className='footer-link' to='/faq'>FAQ</Link>
                            <Link className='footer-link' to='/haurakirailtrail'>Trail Info</Link>
							<Link className='footer-link' to='/new-booking'>Book Now</Link>
							<Link className='footer-link' to='/contact'>Contact</Link>
						</div>
					</div>
				</div>
				<hr />
				<div className="text-center">
				<img src='/images/logo-white.png' alt='Adventure Bike Hire'/>

				</div>
    		</section>
    		<style jsx='true'>
    		{`
    			.footer {
    				background: #333;
    				color: white;
    				padding: 10px;
    			}

    			.footer img {
    				width: 100px;
    			}

    			.footer hr {
    				border-top: 1px solid #fff;
    			}

    			.footer-link{
    				color:white;
    				font-weight:100;
    				display:block;
					text-decoration:none;
    			}
    			.footer-link:hover {
    				color:white;
    				text-decoration:none;
    			}
    		`}</style>
    	</React.Fragment>
    )
}

export default Footer