import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import Header from './components/header/'
import Footer from './components/footer/'
import Home from './pages/Home'
import Bikes from './pages/Bikes'
import TrailInfo from './pages/TrailInfo'
import Booking from './pages/Booking'
import BikesForSale from './pages/BikesForSale'

/* new booking flow */
import NewBooking from './pages/NewBooking'
import BookingFinal from './pages/BookingFinal'


/*  TEST */
import TestPay from './pages/TestPay'


import FAQ from './pages/FAQ'
import Contact from './pages/Contact'
import BookingSuccess from './pages/BookingSuccess'
import PrintShuttlePriceList from './pages/PrintShuttlePriceList'
import Shuttle from './pages/Shuttle'
import Packages from './pages/Packages'
import BookingSubmitted from './pages/BookingSubmitted'
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'
import BookNowButton from './components/header/booknowButton'
import AdventureShuttle from './pages/AdventureShuttle'
import Grabone from './pages/Grabone'

import './main.css'

const history = createBrowserHistory()
history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

class App extends Component {

  render() {
    return (
      <Router history={history}>
        {history.location.pathname.indexOf('adventureshuttle') !== -1 ?
          <Route path={'/adventureshuttle/:date?/:time?/:id?'} component={() => <AdventureShuttle />} />
          :
          history.location.pathname.toLowerCase().indexOf('grabone') !== -1 ?
          <Route exact path={'/grabone'} component={() => <Grabone/>} />
          :
          <div className='Site'>
            <div className='Site-content'>
              <Header />
              <Route exact path={'/'} component={() => <Home />} />
              <Route exact path={'/booking'} component={() => <Booking />} />
              <Route path={'/bookings/:param?'} component={() => <Booking />} />

              <Route exact path={'/new-booking'} component={() => <NewBooking />} />
              <Route exact path={'/new-booking/:bookingid'} component={() => <BookingFinal />} />
              <Route exact path={'/testpay'} component={() => <TestPay />} />
              <Route exact path={'/bikehire'} component={() => <Bikes />} />
              <Route exact path={'/faq'} component={() => <FAQ />} />
              <Route exact path={'/contact'} component={() => <Contact />} />
              <Route exact path={'/booking_success'} component={() => <BookingSuccess/>} />
              <Route exact path={'/print-shuttle-pricelist'} component={() => <PrintShuttlePriceList/>} />
              <Route exact path={'/booking-submitted'} component={() => <BookingSubmitted/>} />
              <Route exact path={'/shuttle'} component={() => <Shuttle/>} />
              <Route exact path={'/packages'} component={() => <Packages/>} />
              <Route exact path={'/haurakirailtrail'} component={() => <TrailInfo/>} />
              <Route exact path={'/bikesforsale'} component={() => <BikesForSale/>} />
              
            </div>
            <Footer />
            <BookNowButton/>
          </div>
        }
      </Router>
    );
  }
}

export default App;