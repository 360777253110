import React from "react";
import { FaTimes } from "react-icons/fa/";
import rates from '../../constants/rentalRates'
const images = { mtb: '/images/san_rafael_ds1.jpg', ebike: '/images/ebike_sm.jpg', comfort: '/images/comfort_sm.jpg', hybrid_low: '/images/comfort_sm.png', kids: '/images/kids_sm.jpg'}
const ft_in = ['4\' 10"', '4\' 11"', '5\'', '5\' 1"', '5\' 2"', '5\' 3"', '5\' 4"', '5\' 5"', '5\' 6"', '5\' 7"', '5\' 8"', '5\' 9"', '5\' 10"', '5\' 11"', '6\'', '6\' 1"', '6\' 2"']
const cm = [147,150,152,155,157,160,163,165,168,170,173,175,179,180,183,185,188]

export default class BikeView extends React.Component {

    

    onChange = ev => {
        const { idx, bike, onUpdate } = this.props
        const key= ev.target.id
        if (key === 'height')
            bike.height = parseInt(ev.target.value, 10)
        else bike[key] = ev.target.value
        onUpdate(idx, bike)
    }

    onOptionSelect = ev => {
        const optName = ev.target.id.indexOf('trailer') !== -1
            ? 'trailer'
            : ev.target.id.indexOf('seat') !== -1
            ? 'seat'
            : 'panniers' 
        
        const { idx, bike, onUpdate } = this.props
        bike[optName] = !bike[optName] 
        onUpdate(idx, bike)
    }

    getTitle = () => {
        const type = this.props.bike.type
        switch (type) {
            case 'mtb': return "Dual Sport/Mountain Bike"
            case 'hybrid_low': return "Dual Sport Step Thru"
            case 'comfort': return "Comfort Bike"
            case 'ebike': return "E-Bike"
            case 'kids': return "Kids Bike"
            default: return;
        }
    }

	render(){
        const { idx, bike, onDelete, days, isSeatAvailable, isTrailerAvailable } = this.props
		return (
            <>
                <div className="col-12 col-md-6 my-3 bike-view-col">
                    <div className="card">
                        <button onClick={()=>onDelete(idx)}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>
                        <div className="bike-view-header">
                            <div className='bike-view-header-title'><b>{this.getTitle()}</b> {bike.cost && <>- NZD${bike.cost}</>}</div>
                        </div>
                        <img src={images[bike.type]} alt="" className="card-img-bottom"/>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="form-group">
                                    <input value={bike.name || ''} onChange={this.onChange} id='name' className='form-control' placeholder={'Rider ' + (idx+1)} />
                                </div>
                                { bike.type === 'kids'
                                ?<div className="form-group">
                                    <label className="form-label"><small><b>Select Age</b></small></label>
                                    <select value={bike.age || ''} onChange={this.onChange} id='age' className='form-control'>
                                        <option></option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>

                                    </select>
                                    {!bike.age && <p className='text-danger mt-2'><small>Please select an age for this rider</small></p>}

                                </div>
                                :<>
                                <div className="form-group">
                                    <label className="form-label"><small><b>Please provide your height</b></small></label>
                                    <select value={bike.height || ''} onChange={this.onChange} id="height" className="form-control">
                                        <option></option>
                                        { cm.map( (o, i) =>
                                            <option key={i} value={o}>{ft_in[i] + '   ('+ o + 'cm)'}</option>)
                                        }
                                    </select>
                                </div>
                                { isTrailerAvailable &&
                                <div className="form-check">
                                    <input checked={bike.trailer || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'trailer_check_'+idx}/>
                                    <label htmlFor={"trailer_check_" + idx} className="form-check-label">Add Kiddie Trailer (+NZD${rates.trailer*days})</label>
                                </div>
                                }
                                { isSeatAvailable &&
                                <div className="form-check">
                                    <input checked={bike.seat || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'childseat_check_'+idx}/>
                                    <label htmlFor={"childseat_check_"+idx} className="form-check-label">Add Child Seat (+NZD${rates.childSeat*days})</label>
                                </div>
                                }
                                <div className="form-check">
                                    <input checked={bike.panniers || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'panniers_check_'+idx}/>
                                    <label htmlFor={"panniers_check_"+idx} className="form-check-label">Add Panniers (+NZD${rates.panniers*days})</label>
                                </div>

                                {!bike.height && <p className='text-danger mt-2'><small>Please select a height for this rider</small></p>}
                            </>}

                            </div>
                        </div>
                    </div>
                </div>

                <style jsx='true'>
                    {`

                    @media (max-width: 575px) {
                        .bike-view-col {
                            padding: 0px!important;
                        }
                    }

                    .bike-view-header {
                        background: #027ab7;
                        padding: 0.5em 1em;
                    }

                    .bike-view-delete-button {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        padding: 0;
                        border: 3px solid #fff;
                        padding-bottom: 5px;
                        box-shadow: none;
                    }

                    .bike-view-header-title {
                        color: #fff;
                        font-weight: 400;
                        font-size: 1.2em;
                    }
        
                    `}
                </style>
            </>
		)
	}
}
