import React from "react";

export default class LuggageView extends React.Component {

    render(){

        const { cost, fromTown, toTown, date, bags } = this.props.luggage

		return (
            <div className="row border-bottom py-2" key={this.props.idx}>
                <div className="col"><img className='thumb' src='/images/luggage.jpg' alt=''/></div>
                <div className="col">
                    <b>Luggage Transfer</b><br/>
                    From <em>{fromTown}</em> to <em>{toTown}</em><br/>
                    {date}
                </div>

                <div className="col">
                    {!!bags && <>{bags} Bags<br/></>}
                    <span className="text-success font-weight-bold">${cost}</span>
                </div>
            </div>
		)
	}
}