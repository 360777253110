import React from "react";
import { withRouter } from 'react-router'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import axios from 'axios'
import { apiUrl } from '../constants/apiurl'

const ft_in = ['4\' 10"', '4\' 11"', '5\'', '5\' 1"', '5\' 2"', '5\' 3"', '5\' 4"', '5\' 5"', '5\' 6"', '5\' 7"', '5\' 8"', '5\' 9"', '5\' 10"', '5\' 11"', '6\'', '6\' 1"', '6\' 2"']
const cm = [147,150,152,155,157,160,163,165,168,170,173,175,179,180,183,185,188]

class Grabone extends React.Component {

	state = { 
        date: new Date(), 
        pax: 2, 
        upgrade: 0, 
        p1Name:'', p1Height:null, p2Name:'', p2Height:0,
        phone:'', email:'',
        pending: false,
    }

	componentDidMount() {
		window.scrollTo(0, 0)
    }

    onSubmit = ev => {
        ev.preventDefault()
        this.setState({pending: true})
        let json = {
            from: moment(this.state.date).format('YYYYMMDD') + 'T1000',
            to: moment(this.state.date).format('YYYYMMDD') + 'T1000',
            bikes: this.getRidersArray(),
            payment: {method: 'grabone', total: 0},
            shuttles: [{pax: this.state.pax, time: '1000', fromTown: 'waihi', toTown: 'paeroa'}],
            customer: {
                name: this.state.p1Name + (this.state.pax === 2 ? ' & ' + this.state.p2Name : ''),
                phone: this.state.phone,
                email: this.state.email,
                notes: 'Voucher Number: ' + this.state.voucher + ', Grab One Booking - upgrades: ' + (this.state.upgrade === 0 ? 'None' : this.state.upgrade === 1 ? 'Thames to Waihi +$25pp' : 'Te Aroha to Waihi +$20pp'),
            }
        }

        const URL = apiUrl + '/submitBookingGrabone'
        axios.post(URL, json)
        .then(res => {
            window.location.href='https://adventurebikehire.co.nz/booking-submitted'
        }).catch(err => {
            console.log(err)
            this.setState({pending: false})
            alert('There was an error submitting your booking.  Please try again.   ' +err.msg)
        })

    }

    getRidersArray = () => {
        let arr = []
        arr.push( { type: 'ebike', name: this.state.p1Name, height: parseInt(this.state.p1Height), cost: 95})
        if (this.state.pax === 2) {
            arr.push({type: 'ebike', name: this.state.p2Name, height: parseInt(this.state.p2Height), cost: 95})
        }
        return arr
    }

    onChange = ev => {
        this.setState({[ev.target.id]: ev.target.value})
    }
    
    handleDayClick = (date, disabled) => {
        if (disabled.disabled) {}
        else this.setState({date})
    }

    onChangePax = ev => {
        this.setState({pax: parseInt(ev.target.value)})
    }

    onUpgradeCheckbox = ev => {
        if (ev.target.checked) {
            if (ev.target.id==='check1') this.setState({upgrade: 1})
            else if (ev.target.id==='check2') this.setState({upgrade: 2})
        } else {
            this.setState({upgrade: 0})
        }
    }

	render(){

        const { pending, date, pax, upgrade, p1Name, p1Height, p2Name, p2Height, phone, email, voucher } = this.state

		return (
            <form onSubmit={this.onSubmit}>
                <div className="container">
                    <h1>The Hauraki Rail Trail E-Bike Package</h1>
                    <div className="lead mb-3">Shutte departs 10am from Waihi Station</div>
                    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2241.275237852234!2d175.83176404474955!3d-37.398301619963036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6d91e1899f060f%3A0x2e68f1a76120c6fb!2sGoldfields%20Railway%20Inc.!5e0!3m2!1sen!2snz!4v1604388351966!5m2!1sen!2snz" width="400" height="300" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>


                    <div className='mt-5'><b>Booking Date</b></div>
                    <DayPicker
                        selectedDays={[date]}
                        onDayClick={this.handleDayClick}
                        disabledDays={[
                            {before: new Date()}, 
                            {daysOfWeek: []},
                            new Date(2023,11,25), new Date(2024,0,1),
                            {after: new Date(2024, 2, 31)}
                        ]}
                    />

                    <div className="form-group my-4">
                        <label><b>My GrabOne voucher is for...</b></label>
                        <select value={pax} disabled={pending} className='form-control' onChange={this.onChangePax}>
                            <option value={1}>1 Person</option>
                            <option value={2}>2 People</option>
                        </select>
                    </div>

                    <div className="form-group my-4">
                        <label><b>Voucher Number...</b></label>
                        <input disabled={pending} className='form-control my-3' required value={voucher} id='voucher' onChange={this.onChange} placeholder='Voucher #'></input>
                    </div>

                    <div className='bg-info p-3 mt-5'>
                        <b>Rider 1:</b>
                        <input disabled={pending} className='form-control my-3' required value={p1Name} id='p1Name' onChange={this.onChange} placeholder='Name'></input>

                        <select disabled={pending} value={p1Height||''} required onChange={this.onChange} id="p1Height" className="form-control">
                            <option disabled value=''>Select Height</option>
                            { cm.map( (o, i) =>
                                <option key={i} value={o}>{ft_in[i] + '   ('+ o + 'cm)'}</option>)
                            }
                        </select>
                    </div>

                    {pax === 2 && 
                    <div className='bg-info p-3 mt-2'>
                        <b>Rider 2:</b>
                        <input disabled={pending} className='form-control my-3' required={pax===2} value={p2Name} id='p2Name' onChange={this.onChange} placeholder='Name'></input>

                        <select disabled={pending} value={p2Height || ''} onChange={this.onChange} id="p2Height" className="form-control" required={pax === 2}>
                            <option disabled value=''>Select Height</option>
                            { cm.map( (o, i) =>
                                <option key={i} value={o}>{ft_in[i] + '   ('+ o + 'cm)'}</option>)
                            }
                        </select>
                    </div>
                    }

                    <div className="form-group my-5">
                        <label><b>Contact Information</b></label>
                        <input disabled={pending} className='form-control' onChange={this.onChange} value={phone} id='phone' required placeholder='Phone Number'></input>
                        <input disabled={pending} className='form-control my-3' onChange={this.onChange} value={email} id='email' required type='email' placeholder='Email Address'></input>
                    </div>

                    <h2>Upgrade Your Deal</h2>
                    <div className="lead mb-3">Do you want more of a challenge and the chance to see more of the Hauraki Rail trail?  The following upgrades to the GrabOne deal are available.  </div>
                    
                    <div className="form-check">
                        <input disabled={pending} type='checkbox' checked={upgrade===1} className='form-check-input' id='check1' onChange={this.onUpgradeCheckbox}/>
                        <label className='form-check-label' htmlFor='check1'>Thames to Waihi (58km) +$25/person</label>
                    </div>

                    <div className="form-check">
                        <input disabled={pending} type='checkbox' checked={upgrade===2} className='form-check-input' id='check2' onChange={this.onUpgradeCheckbox}/>
                        <label className='form-check-label' htmlFor='check2'>Te Aroha to Waihi (47km) +$20/person</label>
                    </div>

                    <div className='mt-2'><small>
                    -    Departure time and location for the shuttle pickup remains unchanged. <br/>
                    -    Upgrades must be paid to the driver upon pickup (eftpos available)
                    </small></div>

                    <button disabled={pending} className='btn btn-primary btn-block btn-lg my-5'>
                        { pending 
                        ?<>Please wait <span className='spinner-grow mr-2'></span></>
                        :<>Book Now for {moment(date).format('Do MMM')}</>
                        }
                    </button>
                    <div className='text-center'>
                        <small className='text-muted my-5'>&copy; Adventure Bike Hire & Shuttle 2023</small>
                    </div>
                </div>
            </form>
		)
	}
}

export default withRouter(Grabone)