import React from "react";
import { withRouter, Link } from 'react-router-dom'
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap'

class Header extends React.Component {

	getClassNames = (path) => {
		//console.log(path, this.props.location.pathname)
		if (this.props.location.pathname.indexOf(path) !== -1) return 'nav-link nav-selected'
		else if (path === '/home' && this.props.location.pathname === '/') return 'nav-link nav-selected'
		else return 'nav-link'
	}

	render() {
		return (
    	<div id='header'>
    		<Navbar color='dark' dark>
				<div className="container text-center">
					<div className="row align-items-center">
					<div className="col-12 col-xl-auto">
						<NavbarBrand href='/'><img src='/images/logo.png' alt='Adventure Bike Hire' className='logo-img'/></NavbarBrand>

					</div>
					<div className="col">
					<Nav>
						<NavItem><Link to='/' className={this.getClassNames('/home')}>Home</Link></NavItem>
						<NavItem><Link to='/bikehire' className={this.getClassNames('/bikehire')}>Bike Hire</Link></NavItem>
						<NavItem><Link to='/shuttle' className={this.getClassNames('/shuttle')}>Shuttle</Link></NavItem>
						<NavItem><Link to='/packages' className={this.getClassNames('/packages')}>Packages</Link></NavItem>
						<NavItem><Link to='/bikesforsale' className={this.getClassNames('/bikesforsale')}>Bikes for Sale</Link></NavItem>
						<NavItem><Link to='/faq' className={this.getClassNames('/faq')}>FAQ</Link></NavItem>
						<NavItem><Link to='/haurakirailtrail' className={this.getClassNames('/haurakirailtrail')}>Trail Info</Link></NavItem>
						<NavItem><Link to='/new-booking' className={this.getClassNames('/new-booking')}>Bookings</Link></NavItem>
						<NavItem><Link to='/contact' className={this.getClassNames('/contact')}>Contact</Link></NavItem>
					</Nav>
					</div>
					</div>
				</div>
    		</Navbar>

    	
    		{/* --- STYLES --- */}
      	<style jsx='true'>
      		{`
      		.nav-drawer {
      			min-width: 220px;
			  }
			  
			  .nav-selected {
				  border-bottom: 1px solid #fbce03;
			  }
      		.nav-drawer .nav-link {
      			color:#000;
      		}
      		.nav-drawer .nav-link:hover{
      			color:#777;
      		}
      		.nav-item{
      			list-style:none;
			  }
			.navbar-brand {
				margin: auto;
			}
			
			.nav {
				border-top: 1px solid #333;
				margin: auto;
			}

      		.nav-link {
      			color:#fff;
			  }
			  
      		.nav-link:hover {
      			color:#eee;
      		}

			  .logo-img {
				  width:225px;
				  margin: 12px 0
      		}

      		.bg-dark {
      			background-color:#231f20 !important;
      			padding: 12px;
			  }

			@media (min-width: 992px) {
				.logo-img {
					width: 250px;
				}
			}

			
      		`}</style>
    	</div>
    )
	}
}

export default withRouter(Header)