import React from "react";
import { withRouter } from 'react-router'
import { apiUrl } from '../constants/apiurl'
import { FaCaretLeft } from "react-icons/fa/";
import BookingSummary from "../components/booking_v3/booking-summary";
import Contact from '../components/booking_v3/contact'
import Payment from '../components/booking_v3/payment'
import Terms from '../components/booking_v3/terms'
import ConfirmButton from '../components/booking_v3/confirm'
import axios from 'axios'
import moment from 'moment'
import { publicKey } from '../constants/stripekey'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'

class BookingProducts extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            working: true,
            payment: {method: 'credit/online'},
            ...props.history.location.state
        }
    }

    onUpdate = json => {
        this.setState({...json})
    }
    

	componentDidMount() {
		window.scrollTo(0, 0)
        // check if booking has been passed through props - if not, fetch from DB
        if (this.state.id) {
            this.setState({working: false})
            console.log('already have state', this.state)

        } else {
            const id = this.props.match.params.bookingid
            const URL = apiUrl + '/getUserBooking?id=' + id
            axios.get(URL)
            .then(res => {
                console.log(res.data)
                if (!res.data.created) this.props.history.push('/new-booking')
                else this.setState({...res.data, working: false})
            }).catch(err => { 
                console.log(err)
                this.props.history.push('/new-booking')
            })
        }
	}

    formatDate = d => {
        const dd = d.split('T')[0]
        return moment(dd, 'YYYYMMDD').format('ddd Do MMM YYYY')
    }

    formatTime = t => {
        const tt = t.split('T')[1]
        return moment(tt, 'hhmm').format('h:mm a')
    }

    goToPreviousPage = () => {
        this.props.history.push('/new-booking', this.state)
    }

    setTotal = total => {
        let { payment = {}} = this.state
        payment.total = total
        this.setState({payment})

    }

    render(){

        const { working, customer={}, payment, acceptedTerms } = this.state
        const { stripe } = this.props

		return (
            !!working ? <h5>WORKING...</h5> :

            <div className='container border-left border-right'>
                
                {/* header */}
                <div className="row border-bottom py-3 mb-3" style={{background:'#f0f0f0'}}>
                    <div className="col">
                        <h2>Checkout</h2>
                        <div className="lead">Almost there..</div>
                    </div>
                    <div className="col text-right">
                        <div className="text-primary link mt-2" 
                            onClick={()=>this.props.history.push('/new-booking', this.state)}>
                            <FaCaretLeft/>   Edit Booking
                        </div>
                    </div>
                </div>

                {/* booking summary */}
                <BookingSummary booking={this.state} setTotal={(t)=>this.setTotal(t)} />

                <div className="row border-top">
                        <Contact onUpdate={this.onUpdate} customer ={customer} />
                        <Payment onUpdate={this.onUpdate} payment={payment} />
                        <Terms onUpdate={this.onUpdate} acceptedTerms={acceptedTerms}/>
                </div>

                <ConfirmButton booking={this.state} stripe={stripe}/>

            </div>
		)
	}
}

class Injector extends React.Component {
	constructor() {
    super()
    this.state = {stripe: null}
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(publicKey)})
    } else {
      const script = document.createElement('script')
      script.onload = () => this.setState({stripe: window.Stripe(publicKey)})
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script);
    }
  }

  componentWillUnmount() {

  }

  render() {

  	const InjectedForm = injectStripe(BookingProducts)
    return (
      <StripeProvider stripe={ this.state.stripe }>
        <Elements>
          <InjectedForm 
          	stripe={this.state.stripe} {...this.props}/> 
        </Elements>
      </StripeProvider>
    )
  }
}

export default withRouter(Injector)