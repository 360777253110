import React from "react";
import moment from "moment";
import { FaCaretLeft } from "react-icons/fa/";

const BookBikesHeader = ({from, to, days, back}) => {
    return (
        <>
            <button className='btn btn-link' onClick={back}><FaCaretLeft/> Choose a different date</button>
            <div className='header-border'>
                <div className='booking-header'>
                    {days === 1 
                    ?<h5>{moment(from).format('LL')}</h5>
                    :<h5>{moment(from).format('ll') + ' - ' + moment(to).format('ll')}</h5>
                    }
                </div>
            </div>
            <style jsx='true'>
                    {`
                        .header-border {
                            border: 1px solid #eee;
                            border-bottom: none;
                            padding: 12px;
                        }
                    `}
            </style>
        </>
    )
}

export default BookBikesHeader