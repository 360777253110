import React from "react";
import BookingMenu from '../components/booking_v2/booking-menu'
import BookBikes from '../components/booking_v2/book-bikes'
import BookShuttle from '../components/booking_v2/book-shuttle'
import { withRouter } from 'react-router'

class Bookings extends React.Component {

	state = { booking: {bikes:{}} }

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	getUrlParams = s => {
		let params = {}
		s.split('&').forEach( p => {
			params[p.split('=')[0].replace('?','')] = p.split('=')[1]
		})
		return params
	}

	render(){
		const param = this.props.match.params.param
		const urlParams = this.getUrlParams(this.props.location.search)

		return (
            param === 'bikes'
            ? <BookBikes />
            : param === 'book-shuttle'
            ? <BookShuttle urlParams={urlParams}/>
			: param === 'bikes+shuttle'
            ? <div>packages</div>
            : <BookingMenu />
		)
	}
}

export default withRouter(Bookings)