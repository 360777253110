import React from "react";
import { FaCheck } from "react-icons/fa/";

const times = ['8:00am', '8:15am', '8:30am', '8:45am', '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm', '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm', '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm', '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm']
const times_ = ['0800', '0815','0830','0845','0900','0915','0930','0945','1000','1015','1030','1045','1100','1115','1130','1145','1200','1215','1230','1245','1300','1315','1330','1345','1400','1415','1430','1445','1500','1515','1530','1545','1600','1615','1630','1645','1700','1715','1730','1745','1800']
const TOWNS = ['waihi_beach.0', 'waihi.0', 'waikino.0', 'karangahake.0', 'paeroa.0', 'te_aroha.40', 'matamata.80', 'thames.40', 'miranda.80', 'kaiaua.80', 'other.0']
const TOWNS_DISPLAY = ['Waihi Beach', 'Waihi', 'Waikino', 'Karangahake', 'Paeroa', 'Te Aroha', 'Matamata', 'Thames', 'Miranda', 'Kaiaua', 'Other']

export default class BikeTransport extends React.Component {

    /*
    PROPS = startTown, startAddress, finishTown, finishAddress, cost
    */

    constructor(props) {
		super(props)
        let bikeTransport = this.validate(props.bikeTransport, props.from_time, props.to_time)
        props.update({bikeTransport})
	}

    onChange = ev => {
        let { bikeTransport, update, from_time, to_time } = this.props
        const key = ev.target.id
        if (key === 'from_time' || key === 'to_time') {
            // update times

            if (key === 'from_time') from_time = ev.target.value
            else to_time = ev.target.value
            bikeTransport = this.validate(bikeTransport, from_time, to_time)
            update({[key]: ev.target.value, bikeTransport})
        }
        else {
            bikeTransport[key] = ev.target.value
            bikeTransport.cost = this.getCost(bikeTransport.startTown, bikeTransport.finishTown)
            bikeTransport = this.validate(bikeTransport, from_time, to_time)
            update({bikeTransport: bikeTransport})
        }
    }

    getCost = (start, finish) => {
        if (!start || !finish) return undefined
        return parseInt(start.split('.')[1],10) + parseInt(finish.split('.')[1],10)
    }

    getPlaceholder = startOrFinish => {
        const v = startOrFinish === 'start' ? this.props.bikeTransport.startTown : this.props.bikeTransport.finishTown
        if (v === undefined) return 'Enter Address'
        switch (v.split('.')[0]) {
            case 'shuttle':return 'Combined with passenger shuttle'
            case 'waihi': return 'Trail Carpark (by Goldfields Railway)'
            case 'waikino': return 'Waikino Station'
            case 'karangahake': return 'Karangahake Hall'
            case 'paeroa': return 'Ohinemuri Park (Giant L&P Bottle)'
            case 'thames': return 'Trail Carpark by Wharf Cafe'
            case 'kaiaua': return 'Opposite The Pink Shop'
            case 'te_aroha': return 'Railway Station, Burgess St'
            case 'matamata': return 'Carpark by i-Site'
            case 'miranda': return 'Miranda Shorebird Centre'
            case 'other': return 'Enter address'
            default: return 'Enter Address'
        }
    }

    validate = (b, from_time, to_time) => {
        delete b.validateError
        if (!b.startTown) b.validateError = 'Select a start location'
        else if (!b.finishTown) b.validateError = 'Select a finish location'
        else if (!from_time) b.validateError = 'Select a start time'
        else if (!to_time) b.validateError = 'Select a finish time'
        return b
    }

  	render(){
        const { startTown='', startAddress, finishTown='', finishAddress, cost, validateError } = this.props.bikeTransport
		const { from_time='', to_time='' } = this.props
        return (
            <div className="col-12 col-lg-6 col-xl-4 my-3 bike-view-col">
                <div className="card">
                    <div className="bike-view-header">
                        <div className='bike-view-header-title'><b>Bike Transport {cost >= 0 && <span>- ${cost}</span>}</b></div>
                    </div>
                    <div className="card-body">
                        <div className="card-text">

                            <p><b>We bring the bikes to you!  </b></p>
                            <p className="text-primary">Please complete this section to let us know where & when to meet you to drop off and collect the hire bikes.  Some locations will incur a transport fee.  </p>
                            <p className="text-secondary">If you're not sure what time you will finish, select TBA (to be advised) and let us know on the day.</p>

                            {/* Start */}
                            <div className="mt-3"><b>START</b></div>
                            <div className="form-row">
                                <div className="col-8">
                                    <select className='form-control' value={startTown} id='startTown' onChange={this.onChange}>
                                        <option disabled value=''>Select Start</option>
                                        <option value='shuttle.0' className='text-primary'>via Shuttle</option>
                                        {TOWNS.map((t,i)=>
                                        <option key={i} value={t}>{TOWNS_DISPLAY[i]}</option>)}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <select value={from_time} className="form-control" id='from_time' onChange={this.onChange}>
                                        <option disabled value=''>Time</option>
                                        {times.map((t,i) =>
                                        <option key={i} value={times_[i]}>{t}</option>)}
                                    </select>      
                                </div>
                            </div>
                            <input placeholder={this.getPlaceholder('start')} className='form-control mt-1' value={startAddress} id='startAddress' onChange={this.onChange}/>

                             {/* Finish */}
                             <div className="mt-5"><b>FINISH</b></div>
                             <div className="form-row">
                                <div className="col-8">
                                    <select className='form-control' value={finishTown} id='finishTown' onChange={this.onChange}>
                                        <option disabled value=''>Select Finish</option>
                                        <option value='shuttle.0' className='text-primary'>via Shuttle</option>
                                        {TOWNS.map((t,i)=>
                                        <option key={i} value={t}>{TOWNS_DISPLAY[i]}</option>)}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <select value={to_time} className="form-control" id='to_time' onChange={this.onChange}>
                                        <option disabled value=''>Time</option>
                                        <option value='1801'>TBA</option>T
                                        {times.map((t,i) =>
                                        <option key={i} value={times_[i]}>{t}</option>)}
                                    </select>      
                                </div>
                            </div>
                            <input placeholder={this.getPlaceholder('finish')} className='form-control mt-1' value={finishAddress} id='finishAddress' onChange={this.onChange}/>

                        </div>
                    </div>
                    { !!validateError && <div className="alert alert-info mb-0 rounded-0">{validateError}</div>}
                    { !validateError && <div className="alert alert-success mb-0 rounded-0 lead"><FaCheck/><span className='ml-5'>Bike Transport Cost: ${cost}</span></div>}
                </div>

            <style jsx='true'>
                {`

                @media (max-width: 575px) {
                    .bike-view-col {
                        padding: 0px!important;
                    }
                }
                
                .bike-view-header {
                    background: #027ab7;
                    padding: 0.5em 1em;
                }
                
                .bike-view-delete-button {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    padding: 0;
                    border: 3px solid #fff;
                    padding-bottom: 5px;
                    box-shadow: none;
                }
                
                .bike-view-header-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                }

                .disabled-option {
                    color:#ddd;
                }
                
                `}
            </style>
        </div>
		)
	}
}
