import React from "react";
import { withRouter } from 'react-router'
import SelectDate from './select-date'
import Shuttle from './single-shuttle'
import Contact from './contact'
import Payment from './payment'
import Terms from './terms'
import ConfirmButton from './confirm'
import moment from 'moment'
//import { TOWNS } from '../../components/shuttle-prices'
import { STATIONS_VIEW } from "../shuttle-calc";
import { publicKey } from '../../constants/stripekey'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'

class BookShuttle extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidMount() {
		window.scrollTo(0, 0)
    }

    getInitialState = () => {
        return {
            from: undefined,
            shuttles: this.getShuttleParams(this.props.urlParams),
            payment: {method: 'credit/online', total: 0}
        }
    }

    getShuttleParams = params => {
        let shuttle = {
            pax: params.pax && parseInt(params.pax, 10) > 0 && parseInt(params.pax,10) < 11 ? parseInt(params.pax,10) : undefined,
            fromTown: params.fromTown && STATIONS_VIEW.indexOf(params.fromTown) !== -1 ? params.fromTown : undefined,
            toTown: params.toTown && STATIONS_VIEW.indexOf(params.toTown) !== -1 ? params.toTown : undefined
        }
        return [shuttle]
    }

    onDatesSelected = (d) => {
        this.setState({...d})
    }

    getDatesList = () => {
        let list = []
        let d = moment(this.state.from)
        for (let i = 0; i < this.state.days; i ++) {
            list.push(d.format('ddd Do MMM YYYY'))
            d = d.add(1,'day')
        }
        return list
    }

	render(){
        const { from, customer={}, payment={}, terms={}, shuttles=[] } = this.state
        const { stripe } = this.props
		return (
            <div className="container">
                <h1>Book Shuttle</h1>
                <SelectDate onUpdate={from=>this.setState({from})}/>

                { from && 
                    <Shuttle shuttle={shuttles[0]} onUpdate ={shuttles=> this.setState({shuttles})}/>
                }

                { from && shuttles[0].cost && <>
                    <div className="booking-section">
                        <div className="row">
                            <Contact customer={customer} onUpdate={customer => this.setState({customer})}/>
                            <Payment payment={payment} booking={this.state} onUpdate={payment => this.setState({payment})}/>
                            <Terms terms={terms} onUpdate={terms => this.setState({terms})}/>
                        </div>
                    </div>

                    <ConfirmButton booking={this.state} stripe={stripe}/>
                </>}
                
                <style jsx='true'>
                    {`
                    .booking-header {
                        border-bottom: 1px solid #eee;
                        background: #f7f7f7;
                        margin: -12px;
                        padding: 4px 12px;
                    }

                    .booking-section {
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                        padding: 12px;
                    }

                    .full-width-row {
                        background: #f7f7f7;
                        margin: 12px -12px !important; 
                        border-top: 1px solid #eee;
                        border-bottom: 1px solid #eee;
                        padding: 12px; 
                    }
                    
                    `}
                </style>
            </div>
		)
	}
}

class Injector extends React.Component {
	constructor() {
    super()
    this.state = {stripe: null}
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(publicKey)})
    } else {
      const script = document.createElement('script')
      script.onload = () => this.setState({stripe: window.Stripe(publicKey)})
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script);
    }
  }

  render() {

  	const InjectedForm = injectStripe(BookShuttle)
    return (
      <StripeProvider stripe={ this.state.stripe }>
        <Elements>
          <InjectedForm 
          	stripe={this.state.stripe} {...this.props}/> 
        </Elements>
      </StripeProvider>
    )
  }
}

export default withRouter(Injector)