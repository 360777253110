export const types = { 
    ebike: 'Electric Bike', 
    mtb: 'Mountain Bike',
    hybrid_low: 'Trail - Step Thru',
    comfort: 'Comfort Bike', 
    kids: 'Kids Bike' 
}

export const sizes = {
    XS: 'Extra Small (148cm-157cm)',
    S: 'Small (158cm-167cm)',
    M: 'Medium (168cm-177cm)',
    L: 'Large (178cm-184cm)',
    XL: 'Extra Large (185cm +)'
}

export const kidsSizes = {
    XXS: '20" Wheel (6-7yr olds)',
    XS: '24" Wheel (8-10yr olds)',
    S: '26" Wheel (11-12yr olds)'
}

export const images = { 
    mtb: '/images/mtb_sm.jpg', 
    ebike: '/images/ebike_sm.jpg', 
    comfort: '/images/comfort_sm.jpg', 
    hybrid_low: '/images/comfort_sm.png', 
    kids: '/images/kids_sm.jpg'
}