import React from "react";
import { withRouter } from 'react-router'
import { FaCaretRight, FaCaretDown } from "react-icons/fa/";
import axios from 'axios'
import { apiUrl } from "../../constants/apiurl";

class Progress extends React.Component {
    
    constructor(props) {
        super(props)
        this.state={
            showValidationErrors: false,
        }
    }

    isValid = () => {
        const { bikes=[], shuttles=[], luggage=[], from, to, bikeTransport={} } = this.props.state
        if (!from || !to) return false
        if (!bikes.length && !shuttles.length && !luggage.length) return false
        if (bikes.length && bikeTransport.validateError) return false
        let valid = true
        bikes.forEach ( b => {
            if (b.validateError) valid=false
        })
        shuttles.forEach ( s => {
            if (s.validateError) valid=false
        })

        luggage.forEach (l => {
            if (l.validateError) valid = false
        })
        return valid
    }

    nextPage = () => {
        this.setState({working: true})
        const URL = apiUrl + '/newBookingByUser'

        // sort out state
        let state = JSON.parse(JSON.stringify(this.props.state))
        console.log(state)
        
        state.from = state.from + 'T' + (!state.from_time || state.from_time === 'undefined' ? '0759' : state.from_time)
        state.to = state.to + 'T' + (! state.to_time || state.to_time === 'undefined' ? '1801' : state.to_time)

        delete state.from_time
        delete state.to_time
        delete state.scrollFixed
        delete state.working
        delete state.syncingAvailability
        delete state.availability
        delete state.payment
        delete state.total
        
        axios.post(URL, state)
        .then(res => {
            state.id = res.data
            this.props.history.push('/new-booking/' + res.data, state)
        }).catch(err => {
            console.log(err)
            this.setState({working: false, validateMsg: ['There was a problem submitting your booking.  Please contact us if this error persists. ' + err.msg]})
        })
    }

	render(){
        const debug = false
        const { from, to, bikes=[], shuttles=[], syncingAvailability } = this.props.state
        const { showValidationErrors, working } = this.state
        const isValid = this.isValid()

		return (
            <div>
                
                {!isValid && debug && <>
                    <div className='link' onClick={()=>this.setState({showValidationErrors: !showValidationErrors})}>{showValidationErrors ? <FaCaretDown/> : <FaCaretRight/>}Required Fields</div>

                    {showValidationErrors && <>
                        { (!from || !to) && <div>Select Booking Date/s</div>}
                        { bikes.map( (b,i) => 
                            !!b.validateError && <div>{b.validateError} for Rider {i+1}</div>
                        )}

                        { shuttles.map( (s,i) =>
                            !!s.validateError && <div>{s.validateError} for Shuttle {i+1}</div>
                        )}
                    </>}
                </>}

                <hr className="mt-5"/>
                
                {!isValid && 
                    <p className="small font-weight-bold">Please answer all required fields to continue</p>
                }

                <button 
                    className={isValid && !syncingAvailability ? "btn btn-lg btn-block btn-primary" : "btn btn-lg btn-block btn-outline-secondary"} 
                    disabled={!isValid || syncingAvailability}
                    onClick={this.nextPage}
                    >{working ? 'Please wait..' : 'Continue'}
                </button>
              
            </div>
		)
	}
}

export default withRouter(Progress)