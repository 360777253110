import React from "react";
import { Link } from 'react-router-dom'

const Shuttle = props => {
	return (
  	<React.Fragment>
  			<div className='container-fluid px-0 mt-4 text-white' style={{background: '#555'}}>
  				<div className='container py-5'>
                    <h1 className='mt-0'>Adventure Shuttle Service</h1>
                        <p className='text-white'><b>Our shuttle is available 7 days/week and runs on demand.</b></p>
                        <ul>
                            <li>Flexible booking times and routes</li>
                            <li>Comforatable late model, air conditioned van</li>
                            <li>Competitive Pricing</li>
                        </ul>
                        <p className='lead'><Link to='/shuttle'>Find Out More</Link></p>
                </div>
                <img src='/images/shuttle_crop.jpg' alt='' width='100%'/>
            </div>
   		<style jsx='true'>
	    	{`
	    		.shuttle-img {
                    height: 400px;
                    max-width: 1200px;
                    margin: auto;
                    background: url('images/shuttle.jpg') no-repeat center center;
                    background-size: cover;
                }
    		`}</style>
    	</React.Fragment>
    )
}

export default Shuttle