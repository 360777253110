import React from "react";

const FAQ = props => {
	return (
  	<React.Fragment>
        <div className='border-bottom faq mt-5'>
          <h4 className='text-primary'>{props.question}</h4>
          <p className='ml-3'>{props.answer}</p>
        </div>
        <style jsx='true'>
	    	{`
          .faq h4 {
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight:600;
          }

          .faq p {
            font-weight: 100;
          }
    		`}</style>
    	</React.Fragment>
    )
}

export default FAQ