import React from "react";
import { withRouter } from 'react-router'
import { FaArrowRight } from "react-icons/fa/";
class Menu extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){
		return (
            <div className="container">
                <h1 className='mb-4'>Book Your Next Adventure Online</h1>

                <div className='lead mb-2'>..it's quick, easy, and secure.</div>
                <ul>
                    <li>No booking fees or hidden costs</li>
                    <li>Flexible cancellation policy</li>
                    {/*<li>Real-time availability</li>*/}
                    <li>Secure online payments <b>or</b> pay by cash/card in person</li>
                </ul>

                <div className="row no-gutters">
                    <div className="col col-6"><img src='/images/bikes_sm.jpg' alt='bikes' style={{width:'100%'}}/></div>
                    <div className="col col-6"><img src='/images/shuttle_sm.jpg' alt='shuttle' style={{width:'100%'}}/></div>
                </div>

                <div className="bb-container mx-auto my-4">
                    <button className='btn btn-lg btn-block btn-primary' onClick={()=>this.props.history.push('/bookings/bikes')}>Book Bikes <FaArrowRight className='booking-arrow'/></button>
                </div>

                <div className="bb-container mx-auto my-4">
                    <button className='btn btn-lg btn-block btn-primary' onClick={()=>this.props.history.push('/bookings/book-shuttle')}>Book Shuttle <FaArrowRight className='booking-arrow'/></button>
                </div>
                <div className="bb-container mx-auto my-4">
                    <button className='btn btn-lg btn-block btn-primary' onClick={()=>this.props.history.push('/bookings/bikes')}>Book Both <FaArrowRight className='booking-arrow'/></button>
                </div>

                <div className='alert alert-info mt-5'>
                    <p><b>Please note:</b>  As we don't have a fixed address, you need to book in advance - either online or by phone.</p>
                    <p><b>Short Notice?</b>  No problem.  You can still make a booking online - please call us as well to confirm we have received your booking request.</p>
                </div>

                <style jsx='true'>
                    {`
                        .bb-container{
                            width: 100%;
                            max-width:500px;

                        }

                        .bb-container .btn {
                            font-size: 2em !important;
                            font-weight: 600;
                        }

                        .booking-arrow {
                            color:#f2c605;
                            margin-left: 12px;
                        }
                    `}
                </style>
            </div>
		)
	}
}

export default withRouter(Menu)