import React from "react";
import { FaCheck } from 'react-icons/fa/'
import { types, images } from '../../constants/bikes'

export default class BikeView extends React.Component {

    render(){

        const { type, size, name = 'Rider ' + (this.props.idx+1), cost, panniers, trailer, seat, step } = this.props.bike

		return (
            <div className="row border-bottom py-2" key={this.props.idx}>
                <div className="col"><img className='thumb' src={(type==='ebike' && step === true) ? '/images/ebike_step.jpg': images[type]} alt=''/></div>
                <div className="col">
                    <b>{name}</b><br/>
                    {types[type]}
                    {!! panniers && <div className="small text-primary"><FaCheck/> Panniers</div>}
                    {!! trailer && <div className="small text-primary"><FaCheck/> Kiddie Trailer</div>}
                    {!! seat && <div className="small text-primary"><FaCheck/> Child Seat</div>}
                    {type === 'ebike' && step===true && <div className="small text-primary"><FaCheck/> Step Thru</div>}
                </div>

                <div className="col">
                    Size: <b>{size}</b><br/>
                    <span className="text-success font-weight-bold">${cost}</span>
                </div>
            </div>
		)
	}
}