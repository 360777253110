import React from "react";
import Hero from "../components/hero"
import Intro from '../components/home/intro'
import RailTrail from '../components/home/rail-trail'
import Bikes from '../components/home/bikes'
import Shuttle from '../components/home/shuttle'
import Cta from '../components/cta.js'
import GreatRides from '../components/home/great-rides'
import Reviews from '../components/home/reviews'

export default class Home extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <React.Fragment>
                <Hero />
                <Intro/>
                <RailTrail/>
                <Bikes/>
                <Shuttle/>
                <Cta tease={true}/>
                <img src='/images/group-bikes.jpg' style={{width:'100%'}} alt='Adventure Bike Hire Customers'/>
                <Reviews/>
                <GreatRides/>
            </React.Fragment>
        )
    }
}
