import React from "react";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";

export default class Calendar extends React.Component {

    handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) return

       /* if (!day) {
          this.props.update({from: null, to: null}, true)
        }
*/
        else if (this.props.fixedNumberDays > 0) {
          const _from = moment(day).format('YYYYMMDD')
          const _to = moment(day).add(this.props.fixedNumberDays-1, 'days').format('YYYYMMDD')
          this.props.update({from: _from, to: _to}, true)
        }
        
        else {
            //let _range = {from: moment(this.props.from, 'YYYYMMDD').toDate(), to: moment(this.props.to, 'YYYYMMDD').toDate()}
            let range
            if (!this.props.from || !this.props.to) range = {from: day, to: day}
            else range = DateUtils.addDayToRange(day, {from: moment(this.props.from, 'YYYYMMDD').toDate(), to: moment(this.props.to,'YYYYMMDD').toDate()})
            range.from = range.from ? moment(range.from).format('YYYYMMDD') : null
            range.to = range.to ? moment(range.to).format('YYYYMMDD') : null
            this.props.update(range, true)
        }
    }

	render(){
        let { days } = this.props
        const from = this.props.from ? moment(this.props.from, 'YYYYMMDD').toDate() : null
        const to = this.props.to ? moment(this.props.to, 'YYYYMMDD').toDate() : null
        const modifiers = { start: from, end: to }
        const dateFormat = 'dddd, Do MMM YYYY'

		return (
            <div>
                <div className="text-center text-md-left">
                    <DayPicker
                        className="Selectable"
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        disabledDays={{before: new Date()}}/>

                    <div className="text-center text-md-left text-primary small font-weight-bold">
                    { !from || !to
                        ? <div>Select a Date on the Calendar</div>
                        : <div>Booking for { days === 1 
                            ? <span>{moment(from, 'YYYYMMDD').format(dateFormat)}</span>
                            : <span>{days} days, starting {moment(from, 'YYYYMMDD').format(dateFormat)}</span>}
                        </div>
                    }
                </div>
                </div>
                <style jsx='true'>
                    {`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                          }
                          .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                          }
                          .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                          }
                          .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                          }
                          .DayPicker-Caption {
                            border-bottom: 1px solid #ccc;
                          }

                          .DayPicker-Month {
                            margin:0;
                            margin-top: 1em;
                          }
                        }
                    `}
                </style>
            </div>
		)
	}
}
