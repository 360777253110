import React from "react";
import { getPrice, TOWNS } from '../components/shuttle-prices'
import moment from 'moment'

class Bikes extends React.Component {

	state = { nPax: 1 }

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	updateNpax = ev => {
		this.setState({nPax: ev.target.value})
	}
	
	render(){
		//const { nPax } = this.state
		const tables = [2,3,4,5,6,7,8,9,10]
		return (
	    	<>
	    		<div className='container pt-3'>
	    			<h1>Adventure Shuttle Price List - {moment().format('MMMM YYYY')}</h1>
					{/*
					<div>
						<label htmlFor=""># of Passengers</label>
						<select className='form-control' value={nPax} onChange={this.updateNpax}>
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
							<option>7</option>
							<option>8</option>
							<option>9</option>
							<option>10</option>
						</select>
					</div>

					<br/>
					*/}
					{tables.map(n =>
							<table key={n} className='shuttle-price-table table table-bordered' id={(n-1)%3 === 0 ? "page-break" : ''}>
								<thead>
									<tr>
										<th colSpan='9' className='table-title'>
											{n} Passengers
										</th>
									</tr>
									<tr>
										<th></th>
										{TOWNS.map( (t,i) => i < TOWNS.length-1 &&

										<th key={t}>{t.replace('_', ' ')}</th>)}
									</tr>

								</thead>
								<tbody>
									{TOWNS.map( (from, i) => 
										i>0 &&
										<tr key={from}>
											<th>{from.replace('_', ' ')}</th>
											{TOWNS.map( (to, i2) => i2 < TOWNS.length -1 &&
												<td key={to}>
													{ i2<i
														? to !== from && '$' + getPrice(n, from, to)
														: ''
													}
												</td>
											)}
										</tr>
									)}
								</tbody>
							</table>
					)}

	    		</div>
				<style jsx='true'>
					{`
						th {
							text-transform: capitalize
						}

						.shuttle-price-table {
							margin-top: 48px;
						}

						.table-title {
							text-align: center;
							font-size: 1.5em;
							text-transform: uppercase;
							background: #eee;
							font-weight:100;
						}

						@media print {
							#header, .footer, #intergramRoot { display:none; }
							#page-break {page-break-after: always;}
						 }
					`}
				</style>
	    	</>
	    )
	}
}

export default Bikes