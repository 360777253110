import React from "react";
import { Link } from 'react-router-dom'
import QuickQuote from '../components/shuttle/quickQuote'
import PromoFooter from '../components/promo/footer'
class Shuttle extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){
		return (
	    	<>
	    		<div className='container mt-5'>
                    <h1>Shuttle Services</h1>
                    <p><b>We can transport people, bikes, and luggage anywhere along the Hauraki Rail Trail.  Our shuttle runs on demand and is available for any size group up to 10 persons.</b></p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className='bg-shuttle bg-img'></div>
                            <div className='brand-color-bg'>
                                <p className='shuttle-caption'>Anywhere on the Hauraki Rail Trail and beyond.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className='bg-shuttle_inside bg-img'></div>
                            <div className='brand-color-bg'>
                                <p className='shuttle-caption'>Ride in our comfortable, air-conditioned van.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className='bg-bike bg-img'></div>
                            <div className='brand-color-bg'>
                                <p className='shuttle-caption'>Transport your own bike or hire one of ours.</p>
                            </div>
                        </div>
                    </div>

                    <h5>7 Days</h5>
                    <p>Our shuttle service is available 7 days per week year round including public holidays.  Usual hours of operation are 8am - 6pm.</p>
                    
                    <h5>Availability</h5>
                    <p>Adventure Shuttle runs on demand.  Bookings are subject to availability and may be combined with other fare paying passengers.</p>
                    
                    <h5>Bike Transport</h5>
                    <p>Bring your own bike or hire one or ours.  We can safely transport any type of bike (including eBikes) on our custom built trailer.</p>
                </div>
                <div className="container-fluid mt-5" style={{background:'#d1ecf1'}}>
                    <div className="container">
                        <div className='lead py-5'><Link to='/bookings/book-shuttle'>BOOK ONLINE</Link> or call us on <b>027 398 2866</b></div>
                    </div>
                </div>                    
                <QuickQuote />
                <PromoFooter />
				<style jsx='true'>
                    {`
                    .bg-shuttle {
                        background: url('/images/shuttle_sm.jpg') no-repeat center center;
                    }

                    .bg-shuttle_inside {
                        background: url('images/shuttle_inside.jpg') no-repeat center center;
                    }

                    .bg-bike {
                        background: url('images/transport.jpg') no-repeat center center;
                    }

                    .bg-img {
                        height: 200px;
                        width: 100%;
                        background-size: cover;
                    }

                    .shuttle-caption {
                        color: #fff;
                        padding: 4px 6px;
                        text-align: center;
                    }
					
					`}
				</style>
	    	</>
	    )
	}
}

export default Shuttle