import React from "react";

export default class GreatRides extends React.Component {
	
	render(){

		return (
	  		<React.Fragment>
	  			<div className='container my-5'>
	  				<div className='row align-items-center'>
	  					<div className='d-none d-md-block col-4 text-right'>
	  						<img className='great-rides-img' src='/images/great-rides.gif' alt='Great Rides App' />
	  					</div>
	  					<div className='col-12 col-md-8'>
	  						<h2 className='text-primary'>Great Rides App</h2>
	  						<p className='lead'>NZ's only mobile app for all the great rides.</p>
	  						<p>Featuring all 22 of the great rides in NZ including the Hauraki Rail Trail, this is the must-have app for anyone cycling the trail.</p>

	  						<a target='_blank' rel="noopener noreferrer" href='https://itunes.apple.com/us/app/great-rides/id1181203029?mt=8'>
	  							<img width='200px' src='/images/apple-badge.png' alt='Download on the App Store'/>
	  						</a>
	  						<a target='_blank' rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.mytoursapp.android.app906'>
	  							<img width='200px' src='/images/google-badge.png' alt='Get it on Google Play'/>
	  						</a>
	  					</div>
	  				</div>
	  			</div>
	  		
				<style jsx='true'>
		    	{`
		    		.great-rides-img {
		    			width: 100%;
		    			max-width: 200px;
		    		}   	

	    		`}</style>
	    	</React.Fragment>
	    )
	}
}

