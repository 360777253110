import React from "react";
import ReactDOM from 'react-dom';
import { FaTimes, FaCheck, FaPlus, FaInfoCircle } from "react-icons/fa/";
import rates from '../../constants/rentalRates'
import { types, kidsSizes, sizes, images } from '../../constants/bikes'

export default class BikeView extends React.Component {

    constructor(props) {
		super(props)
		this.days = props.days
        const { bikes, idx } = props
        bikes[idx] = this.validate(bikes[idx])
        props.update({bikes})
	}

    componentDidMount() {

        if (!this.props.bike.hideCloseButton) {
            const bodyY = document.body.getBoundingClientRect().top
            var yPos = ReactDOM.findDOMNode(this).getBoundingClientRect().top
            if (yPos !== 0) window.scrollTo({left: 0, top: yPos-bodyY-24, behavior: 'smooth'})
        }
    }
    
    componentDidUpdate(props) {
        if (this.days !== props.days) {
            // revalidate cost if #days has changed
            this.days = props.days
            const { bikes, idx, update } = props
            bikes[idx] = this.validate(bikes[idx])
            update({bikes})
        }
    }

    addBikeHire = () => {
        let { bikes,update } = this.props
        bikes.push({})
        update({bikes})
    }

    onChange = ev => {
        const { idx, update, bikes } = this.props
        const key= ev.target.id
        if (key === 'type') {
            delete bikes[idx].size
            if (ev.target.value === 'kids') {
                delete bikes[idx].trailer
                delete bikes[idx].seat
                delete bikes[idx].panniers
            }
        }
        if (key === 'step') {
            if (ev.target.value === 'deselect') delete bikes[idx].step
            else bikes[idx].step = ev.target.value === 'true' || 'No'
            
            // deselect L/XL size if stepthru is true
            if (bikes[idx].step === true) {
                if (bikes[idx].size === 'L' || bikes[idx].size === 'XL')
                    delete bikes[idx].size
            }
        }
        else bikes[idx][key] = ev.target.value
        bikes[idx] = this.validate(bikes[idx])
        update({bikes})
    }

    validate = b => {
        delete b.validateError
        delete b.cost
        if (!b.type) b.validateError = 'Select a Bike'
        else if (!b.size) b.validateError = 'Select a Bike Size'
        else if (this.props.days === null) b.validateError = 'Select a booking date'
        else b.cost = this.getPrice(b)
        return b
    }

    getPrice = b => {
        if (this.props.packagePrice) { 
            const extras = this.props.days * ((b.trailer ? rates.trailer : 0) + (b.panniers ? rates.panniers : 0)+ (b.seat ? rates.childSeat : 0))
            if (b.type === 'ebike' || b.type === 'ebike_low') return this.props.packagePrice.ebike + extras
            else if (b.type === 'kids') return this.props.packagePrice.kids
            else return this.props.packagePrice.adult + extras
        }

        else {
            let dayRate
            if (b.type === 'kids') dayRate = rates.kidsBike
            else {
                if (b.type === 'ebike' || b.type === 'ebike_low') dayRate = rates.ebike
                else dayRate = rates.adultBike
                dayRate += b.trailer ? rates.trailer : 0
                dayRate += b.panniers? rates.panniers : 0
                dayRate += b.seat? rates.childSeat : 0
            }

            return this.props.days * dayRate
        }
    }

    onOptionSelect = ev => {
        const optName = ev.target.id.indexOf('trailer') !== -1
            ? 'trailer'
            : ev.target.id.indexOf('seat') !== -1
            ? 'seat'
            : 'panniers' 
        
        const { idx, update, bikes } = this.props
        bikes[idx][optName] = !bikes[idx][optName] 
        bikes[idx] = this.validate(bikes[idx])
        update({bikes})
    }

    onDelete = () => {
        const { idx, bikes, update } = this.props
        bikes.splice(idx,1) 
        update({bikes})
    }

    getSizeOption = key => {
        const { bike, bikes, availability, idx } = this.props
               
        if (!availability[bike.type]) return null
        if ( !(key in availability[bike.type])) return null
        if (bike.type === 'ebike' && bike.step === true && (key === 'L' || key === 'XL')) return null

        let availableCount = availability[bike.type][key]
        bikes.forEach( (r, _idx) => {
            if (r.type === bike.type && r.size === key && _idx !== idx) {
                availableCount--
            }
        })

        const isAvailable = availableCount > 0
        
        if (bike.type === 'kids') return <option value={key} className={!isAvailable ? 'disabled-option' : ''} disabled={!isAvailable} key={key}>{kidsSizes[key]}</option>
        return <option value={key} className={!isAvailable ? 'disabled-option' : ''} disabled={!isAvailable} key={key}>{sizes[key]}</option>
    }

    isBookedOut = () => {
        const { availability, bike } = this.props
        if (!availability || !bike.type) return null
        let r = true
        Object.keys(availability[bike.type]).forEach( k => {
            if (availability[bike.type][k] > 0) r = false
        })

        return r
    }

	render(){
        const { idx, bike, days, availability, syncingAvailability } = this.props
        return (
            <div className="col-12 col-lg-6 col-xl-4 my-3 bike-view-col">
                <div className="card">
                    {!bike.hideCloseButton && <button onClick={this.onDelete}className='btn btn-sm btn-secondary bike-view-delete-button'><FaTimes/></button>}
                    <div className="bike-view-header">
                        <div className='bike-view-header-title'><b>{types[bike.type] || <span>Rider {idx+1}</span>}</b> {bike.cost && <>- NZD${bike.cost}</>}</div>
                    </div>
                    <img src={
                        
                        (bike.type === 'ebike' && bike.step === true) ? 'images/ebike_step.jpg' :
                        images[bike.type] ? images[bike.type] : 'images/default.png'} alt="" className="card-img-bottom"/>
                    {!!bike.type && <p className="font-weight-light font-italic mx-2">* Picture is for illustrative purposes and actual bike may differ</p>}
                    <div className="card-body">
                        <div className="card-text">
                            
                            {/* RIDER NAME */}
                            <div className="form-group">
                                <input value={bike.name || ''} onChange={this.onChange} id='name' className='form-control' placeholder={'Rider ' + (idx+1)} />
                            </div>
                            
                            {/* BIKE TYPE */}
                            <div className="form-group">
                                <label className="form-label"><small><b>Choose Bike</b></small></label>
                                <select value={bike.type || ''} onChange={this.onChange} id="type" className="form-control">
                                    <option></option>
                                    { Object.keys(types).map( (key, i) =>
                                        <option key={i} value={key}>{types[key]}</option>)
                                    }
                                </select>
                            </div>
                            {bike.type && bike.type==='ebike' &&
                            <div className="alert alert-primary mt-2"><FaInfoCircle/><span className="mx-2">Riders must be 16 yrs or older to hire our ebikes</span></div>
                            }

                            { /* Step THRU? */}
                            {!!bike.type && bike.type === 'ebike' &&
                            <div className="form-group">
                                <label className="form-label"><small><b>Step Thru / Low Entry Frame? </b></small></label>
                                <select value={bike.step || ''} onChange={this.onChange} id="step" className="form-control">
                                    <option value='deselect'>Don't Mind</option>
                                    <option value={true}>Yes</option>
                                    <option value='No'>No</option>
                                    
                                </select>
                            </div>}
                            
                            { !!syncingAvailability && <img src='/images/spinner.svg' alt=''/>}

                            {/*  BIKE SIZE  */}
                            { bike.type && 
                            <div className="form-group">
                                <label className="form-label"><small><b>Bike Size</b></small></label>
                                {!!this.isBookedOut() ? <p className="text-danger">No options available, please try a different date or bike type.</p>
                                :<select value={bike.size || ''} onChange={this.onChange} id="size" className="form-control">
                                    { !!syncingAvailability &&<option disabled>Loading, Please wait..</option> }
                                    { !syncingAvailability && !availability && <option disabled>Please select a date to load available options</option>}
                                    { !!availability &&<option></option>}
                                    {!!availability && Object.keys(bike.type === 'kids' ? kidsSizes : sizes).map( (key, i) =>
                                        this.getSizeOption(key)
                                    )}

                                </select>}
                            </div>}

                            { bike.type !== 'kids' && <>
                            <div className="form-check">
                                <input checked={bike.seat || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'seat_check_'+idx}/>
                                <label htmlFor={"seat_check_" + idx} className="form-check-label">Add Child Seat (+NZD${rates.childSeat*days})</label>
                            </div>
                            <div className="form-check">
                                <input checked={bike.trailer || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'trailer_check_'+idx}/>
                                <label htmlFor={"trailer_check_" + idx} className="form-check-label">Add Kiddie Trailer (+NZD${rates.trailer*days})</label>
                            </div>
                            <div className="form-check">
                                <input checked={bike.panniers || false} onChange={this.onOptionSelect} className='form-check-input' type='checkbox' id={'panniers_check_'+idx}/>
                                <label htmlFor={"panniers_check_"+idx} className="form-check-label">Add Panniers (+NZD${rates.panniers*days})</label>
                            </div>
                            </>}
                        </div>
                    </div>
                    { !!bike.validateError && <div className="alert alert-info mb-0 rounded-0">{bike.validateError}</div>}
                    { !bike.validateError && !!bike.cost && !this.props.packagePrice && <>
                    <div className="alert alert-success mb-0 rounded-0 lead"><FaCheck/>
                        <span className='ml-5'>Bike Cost: ${bike.cost}</span>
                    </div>
                    <button onClick={this.addBikeHire} className="btn btn-link btn-sm text-right"><FaPlus/> Add Another Rider</button>
                    </>}
                </div>

            <style jsx='true'>
                {`

                @media (max-width: 575px) {
                    .bike-view-col {
                        padding: 0px!important;
                    }
                }
                
                .bike-view-header {
                    background: #027ab7;
                    padding: 0.5em 1em;
                }
                
                .bike-view-delete-button {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    padding: 0;
                    border: 3px solid #fff;
                    padding-bottom: 5px;
                    box-shadow: none;
                }
                
                .bike-view-header-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                }

                .disabled-option {
                    color:#ddd;
                }
                
                `}
            </style>
        </div>
		)
	}
}
