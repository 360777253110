import React from "react";
import TrailStatus from '../components/booking_v3/trail-status'
class TrailInfo extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){
		return (
	    	<>
	    		<div className='container mt-5'>
                    <h1>Trail Info & Partners</h1>
                    <TrailStatus/>
                    
                    <div className="card-columns">
                        {/* GREAT RIDES APP */}
                        <div className='card partner-container'>
                            <a href='https://www.greatridesapp.com/haurakirailtrail' target="_blank" rel="noopener noreferrer">
                                <img src='/images/great-rides.png' alt='Great Rides App'/>
                            </a>
                        </div>
                        
                        {/* HAURAKI RAIL TRAIL */}
                        <div className='card partner-container'>
                            <a href='https://haurakirailtrail.co.nz' target="_blank" rel="noopener noreferrer">
                                <img src='/images/hrt.jpg' alt='HRT'/>
                            </a>
                        </div>
                        
                        {/* GOLDMINE MOTEL */}
                        <div className='card partner-container'>
                            <a href='https://goldminemotel.co.nz' target="_blank" rel="noopener noreferrer">
                                <img src='/images/goldmine-motel.png' alt='Goldmine Motel Waihi'/>
                                <img src='/images/goldmine-motel-room.jpg' alt='Goldmine Motel Waihi'/>
                            </a>
                        </div>
                    </div>
                </div>

                <style>
                {`
                    .partner-container img{
                        width: 100%;
                        max-width: 400px;
                        padding: 12px;
                    }

                    @media (max-width: 991.98px) { 
                        .card-columns {
                            column-count: 2;
                        
                        }
                    }

                    @media (max-width: 700px) { 
                        .card-columns {
                            column-count: 1;
                        
                        }
                    }
                    
                `}
                </style>                   
	    	</>
	    )
	}
}

export default TrailInfo