import React from "react";
import { STATIONS_VIEW, getPrice } from "../shuttle-calc";
import { withRouter } from 'react-router-dom'

class Shuttle extends React.Component {

	state = { pax: 2, start: '', finish: '', quote:'' }

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	updateNpax = ev => {
		this.setState({pax: ev.target.value, quote:''})
    }
    
    updateFrom = ev => {
        this.setState({start: ev.target.value, quote:''})
    }

    
    updateTo = ev => {
        this.setState({finish: ev.target.value, quote:''})
    }

    getQuote = ev => {
        ev.preventDefault()
        const quote = getPrice(this.state)
        console.log(quote)
        this.setState({quote})
    }
	
	render(){
		const { pax, start, finish, quote } = this.state
		return (
            <div className='container'>
                <h1>Get A Quick Quote</h1>
                <form onSubmit={this.getQuote}>
                    <div>
                        <label htmlFor="select-from">Start from</label>
                        <select className='form-control' id="select-from" value={start} onChange={this.updateFrom}>
                            <option disabled></option>
                            {STATIONS_VIEW.map( m => <option key={m} value={m}>{m}</option> )}
                        </select>
                    </div>
                    <div className='pt-3'>
                        <label htmlFor="select-to">Going to</label>
                        <select className='form-control' id="select-to" value={finish} onChange={this.updateTo}>
                        <option disabled></option>
                            {STATIONS_VIEW.map( m => <option key={m} value={m}>{m}</option> )}
                        </select>
                    </div>
                    <div className='pt-3'>
                        <label htmlFor="select-pax"># of Passengers</label>
                        <select className='form-control' id="select-pax" value={pax} onChange={this.updateNpax}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                    </div>
                    { quote > 0
                    ?<>
                        <button className='btn btn-success btn-lg btn-block my-5' onClick={()=>this.props.history.push('/bookings/book-shuttle?pax=' + pax + '&fromTown=' + start + '&toTown=' + finish)}>Your Price ${quote} - <b>Click to Book Online</b></button>
                    </>
                    :<button disabled={!start.length || !finish.length || start === finish} className='btn btn-primary btn-block my-5'>Go</button>
                    }
                </form>
            </div>
	    )
	}
}

export default withRouter(Shuttle)