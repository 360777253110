import React from "react";
import { FaBicycle, FaSuitcase, FaShuttleVan } from "react-icons/fa/";

export default class ActionsView extends React.Component {

    addBikeHire = () => {
        let { bikes,update } = this.props
        bikes.push({})
        update({bikes})
    }

    addShuttle = () => {
        let { shuttles,update } = this.props
        shuttles.push({})
        update({shuttles})
    }

    addLuggageTransfer = () => {
        let { luggage,update } = this.props
        luggage.push({})
        update({luggage})
    }

    addVoucher = () => {
    }

	render(){

        const {bikes, shuttles, luggage} = this.props

		return (
            <>
                <div className="col-12 bike-view-col">
                    <div className="action-banner p-2">&nbsp;&nbsp;Select Options to add to Booking</div>
                    <div className="btn-group" style={{width:'100%'}}>
                        <button onClick={this.addBikeHire} className="action-button btn btn-light">
                            <div><FaBicycle/></div>Add Bike Hire
                            {!!bikes.length && <span className="badge badge-light">{bikes.length}</span>}
                        </button>
                        <button onClick={this.addShuttle} className="action-button btn btn-light">
                            <div><FaShuttleVan/></div>Add Shuttle
                            {!!shuttles.length && <span class="badge badge-light">{shuttles.length}</span>}
                        </button>
                        <button onClick={this.addLuggageTransfer} className="action-button btn btn-light">
                            <div><FaSuitcase/></div>Add Luggage Transfer
                            {!!luggage.length && <span className="badge badge-light">{luggage.length}</span>}
                        </button>
                    </div>
                    <hr/>
                </div>

                <style jsx='true'>
                    {`

                    @media (max-width: 575px) {
                        .bike-view-col {
                            padding: 0px!important;
                        }
                    }

                    .action-banner {
                        color: #444444;
                        font-weight: 700;
                        border-top-left-radius: 10px;
                        border-bottom-right-radius:10px;
                    }

                    .action-button {
                        color: #afafaf;
                        background: #ffffff;
                        width:29%;
                        margin: 2%;
                        max-width: 200px;
                        border:none;
                        border-right: 1px solid #bbb;
                    }

                    .action-button:last-of-type {
                        border: none;
                    }

                    .action-button > div {
                        color: #027ab7;
                        width: 100%;
                        font-size: 24px;
                    }

                    .action-button > span {
                        background: #027ab7;
                        color: #fafafa;
                        margin-left: 5px;
                    }

                    `}
                </style>
            </>
		)
	}
}
