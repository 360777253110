import React from "react";

export default class Contact extends React.Component {

    onChange = ev => {
        const { customer, onUpdate } = this.props
        customer[ev.target.id] = ev.target.value
        customer.isValid = !!customer.referral && !!customer.name && customer.name.length > 1 && this.validateEmail(customer.email)
        onUpdate({customer})
    }

    validateEmail = email => {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

	render(){

        const { customer = {} } = this.props
        const { name='', email='', phone='', referral='', notes='', isValid } = customer

		return (
            <div className="col-12 col-lg-4">
                <h5>Contact</h5>
                <div className="form-group">
                    <label className="form-label"><b>Your Name</b></label>
                    <input type="text" className="form-control" value={name} onChange={this.onChange} id='name'/>
                </div>
                <div className="form-group">
                    <label className="form-label"><b>Your Email Address</b></label>
                    <input type="email" className="form-control" value={email} onChange={this.onChange} id='email'/>
                </div>
                <div className="form-group">
                    <label className="form-label"><b>Contact Phone Number</b></label>
                    <input type="text" className="form-control" value={phone} onChange={this.onChange} id='phone'/>
                </div>
                <div className="form-group">
                    <label className="form-label"><b>How did you hear about us?</b></label>
                    <select className='form-control' value={referral} onChange={this.onChange} id='referral'>
                        <option value=''>Please select an option</option>
                        <option>Google Search</option>
                        <option>Social Media - Facebook / Instagram</option>
                        <option>Great Rides App</option>
                        <option>Print Media</option>
                        <option>Word of mouth</option>
                        <option>Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label"><b>Special requests or notes</b></label>
                    <textarea className='form-control' value={notes} onChange={this.onChange} id='notes'></textarea>
                </div>

                <p><em><small>Your booking confirmation will be sent to your email address.  We won't send you any spam email or add to you any mailing lists.</small></em></p>

                {!isValid 
                    ? name.length < 2 ? <p className='text-danger'>Please enter your name</p>
                    : !this.validateEmail(email) ? <p className='text-danger'>Please enter a valid email address</p>
                    : !referral.length ? <p className='text-danger'>Please select an option for <em>'How did you hear about us</em></p>
                    : ''
                    : ''
                }
                
                <hr/>
                <style jsx='true'>
                    {`
                 
                   
                    `}
                </style>
            </div>
		)
	}
}
