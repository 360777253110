import React from "react";
const TOWNS = ['waihi_beach.0', 'waihi.0', 'waikino.0', 'paeroa.0', 'te_aroha.40', 'matamata.80', 'thames.40', 'miranda.80', 'kaiaua.80', 'other.0']
const TOWNS_DISPLAY = ['Waihi Beach', 'Waihi', 'Waikino', 'Paeroa', 'Te Aroha', 'Matamata', 'Thames', 'Miranda', 'Kaiaua', 'Other']

export default class DropoffPickup extends React.Component {

    onChange = ev => {
        const { bikeTransport, onUpdate } = this.props
        const key = ev.target.id
        bikeTransport[key] = ev.target.value
        bikeTransport.cost = this.getCost(bikeTransport)
        onUpdate(bikeTransport)
    }

    getCost = bt => {
        if (!bt.startTown || !bt.finishTown) return undefined
        return parseInt(bt.startTown.split('.')[1],10) + parseInt(bt.finishTown.split('.')[1],10)
    }

    getPlaceholder = startOrFinish => {
        const v = startOrFinish === 'start' ? this.props.bikeTransport.startTown : this.props.bikeTransport.finishTown
        if (v === undefined) return 'Enter Address'
        switch (v.split('.')[0]) {
            case 'shuttle':return 'Add a Passenger Shuttle below'
            case 'waihi': return 'Waihi Station Carpark, Wrigley St'
            case 'waikino': return 'Waikino Station Carpark, cnr Seddon St & SH2'
            case 'paeroa': return 'Ohinemuri Park'
            case 'thames': return 'Danby Field'
            case 'other' : return 'Enter address - additional charges may apply'
            default: return 'Enter Address'
        }
    }
  
	render(){
        const { bikeTransport = {} } = this.props
        const { startTown='', finishTown='', startAddress='', finishAddress='', cost } = bikeTransport
		return (
            <div className='booking-section'>
                <h5>Location</h5>
                <p>We are a mobile only service and can meet you at any location of your choosing to drop off and pick up bikes.  A travel surcharge applies for some areas.</p>
                <p><b>Please indicate where you would like the bikes dropped off and picked up.</b></p>
               
                <div className="form-row">
                    <div className="col-12 col-md-5">
                        <div className="form-group">
                            <label className="form-label"><b>Start</b></label>
                            <select className='form-control' value={startTown} id='startTown' onChange={this.onChange}>
                                <option></option>
                                <option value='shuttle.0' className='text-primary'>via Shuttle</option>
                                {TOWNS.map((t,i)=>
                                    <option key={i} value={t}>{TOWNS_DISPLAY[i]}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="form-group">
                            <label className="form-label d-none d-md-block">&nbsp;</label>
                            <input placeholder={this.getPlaceholder('start')} className='form-control' value={startAddress} id='startAddress' onChange={this.onChange}/>
                        </div>
                    </div>

                    <div className="col-12 col-md-5">
                        <div className="form-group">
                            <label className="form-label"><b>Finish</b></label>
                            <select className='form-control' value={finishTown} id='finishTown' onChange={this.onChange}>
                                <option></option>
                                <option value='shuttle.0' className='text-primary'>via Shuttle</option>
                                {TOWNS.map((t,i)=>
                                    <option key={i} value={t}>{TOWNS_DISPLAY[i]}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="form-group">
                            <label className="form-label d-none d-md-block">&nbsp;</label>
                            <input placeholder={this.getPlaceholder('finish')} className='form-control' value={finishAddress} id='finishAddress' onChange={this.onChange}/>
                        </div>
                    </div>
                </div>

                <p><small>* Please select the <em>'via shuttle'</em> option if you are booking our passenger shuttle - bike transport cost (if any) is included in the shuttle charges.</small></p>

                { !startTown ? <p className='text-danger'>Please select a start location</p>
                : !finishTown ? <p className='text-danger'>Please select a finish location</p>
                : <p className='text-success'><b>Bike Transport - NZD${cost}</b></p>
                }
                
                <style jsx='true'>
                    {`

                    
                    `}
                </style>
            </div>
		)
	}
}
