import React from "react";
import { withRouter } from 'react-router-dom'

class PromoFooter extends React.Component {

	render(){
		return (
            <>
                <div className="promo-footer">
                    <div className="container text-center">
                        <p className='promo-text'>Combine your shuttle with our <span className='brand-color'>Bike Hire</span> service or choose one of our <span className='brand-color'>Packages</span></p>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <button onClick={()=>this.props.history.push('/bikehire')} className='btn btn-primary'>View Bike Hire</button>
                            </div>
                            <div className="col-12 col-lg-6">
                                <button onClick={()=>this.props.history.push('/packages')} className='btn btn-primary'>View Packages</button>
                            </div>
                        </div>
                    </div>
                </div>
               
                <style jsx='true'>

                    {`
                    .promo-footer {
                        background-image: url(/images/battery_ruins.jpg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;  
                        padding: 48px 0;
                    }

                    .promo-footer button {
                        margin: 12px 0;
                        font-size: 1.2em;
                        padding: 6px 48px ;
                    }

                    @media screen and (min-width: 1000px) {
                        .bh-promo-text {
                            font-size: 2.5em !important;
                        }
                    }

                    .bh-promo-container {
                        position:relative
                    }
                    .promo-text {
                        font-size: 2em;
                        color: #fff;
                        font-weight: 700;
                    }

					.bh-promo-img {
                        max-width: 100%
                    }

                    .yellow {
                        color: #fbce03;
                    }
					`}
				</style>
	    	</>
	    )
	}
}

export default withRouter(PromoFooter)