import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

class booknowButton extends Component {
    render() {
        return this.props.location.pathname.indexOf('/bookings') !== -1 ||
        this.props.location.pathname.indexOf('/new-booking') !== -1 ? null :(
            <>
            <button className='btn btn-primary booknow-button' onClick={()=>this.props.history.push('/new-booking')}>Book Now</button>
            <style jsx='true'>
                {`
                    .booknow-button {
                        opacity: .8;
                        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
                        position:fixed;
                        bottom:12px;
                        right:12px;
                        text-transform: uppercase;
                        font-weight: 700;
                        padding: 6px 24px;
                    }
                `}
            </style>
            </>
        )
    }
}

export default withRouter(booknowButton)
