export const STATIONS_VIEW = [ 'Waihi', 'Paeroa', 'Thames', 'Kaiaua', 'TeAroha', 'Matamata']
export const SUGGESTADDRESS = ['Trail Carpark (By Goldfields Railway)', 'Ohinemuri Park (Giant L&P Bottle)', 'Trail Carpark by Wharf Cafe', 'Opposite The Pink Shop', 'Railway Station, Burgess St', 'Carpark by i-Site']

const STATIONS = [ 'Kaiaua', 'Thames', 'Paeroa', 'TeAroha', 'Matamata' ]
const ONE_STAGE = 100 // one stage for 2 pax
const ADDITIONAL_STAGE = 20 // each additional stage for 2 pax
const ADD_PAX = 15 // cost per addition pax
const DISTANCE_SURCHARGE = 45 // surcharge for trips that include miranda or matamata
const DISTANCE_SURCHARGE_TOWNS = ['Matamata', 'Kaiaua']

const getStages = q => {
	console.log(q)
	const plusOne = q.fromTown === 'Waihi' || q.toTown === 'Waihi'
	const idx = [ STATIONS.findIndex(m=> m===q.fromTown), STATIONS.findIndex(m=> m===q.toTown) ]
	if (q.fromTown === 'Waihi') idx[0]= STATIONS.indexOf('Paeroa')
	if (q.toTown === 'Waihi') idx[1]=STATIONS.indexOf('Paeroa')
	if (idx[0] < 0 || idx[1] < 0) return false
	return Math.abs(idx[0] - idx[1]) + (plusOne ? 1 : 0)
}

export const getPrice = q => {
	console.log(q)
	if (q.start) q.fromTown = q.start
	if (q.finish) q.toTown = q.finish

	if (!q.fromTown || !q.toTown) return null
	let cost = 0
	const stages = getStages(q)
	if (!stages) return false
	
	let sc = (DISTANCE_SURCHARGE_TOWNS.indexOf(q.fromTown) === -1 ? 0 : DISTANCE_SURCHARGE) + (DISTANCE_SURCHARGE_TOWNS.indexOf(q.toTown) === -1 ? 0 : DISTANCE_SURCHARGE)
	cost += sc
	cost += ONE_STAGE + ADDITIONAL_STAGE * (stages-1)
	cost += (q.pax > 2 ? q.pax - 2 : 0) * ADD_PAX
	
	return cost
}